import {Button, Col, Form, FormGroup, InputGroup, Modal} from "react-bootstrap";
import {OptionalSelect} from "../common/OptionalSelect";
import React from "react";
import moment from "moment-timezone";
import {addBusinessDays, shortDatePattern} from "../../utils/Dates";
import CalendarButton from "../common/CalendarButton";

export class WorkTaskSnoozeModal extends React.Component {
    constructor(props) {
        super(props);
        let defaultDate = addBusinessDays(new Date(), 1);
        this.state = {
            currentValue: moment(defaultDate).format('M/D/YYYY'),
            currentDate: defaultDate,
            formValidated: false,
            presetValue: "1"
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            let defaultDate = addBusinessDays(new Date(), 1);
            // reset text value to formatted value of selected date
            this.setState({formValidated: false, currentValue: moment(defaultDate).format('M/D/YYYY'), currentDate: defaultDate, presetValue: "1"});
        }
    }

    ok = () => {
        let valid = document.getElementById("date-form").checkValidity();
        this.setState({formValidated: true});
        if (valid) {
            if (this.props.onOk) {
                this.props.onOk(this.state.currentDate);
                this.props.onHide();
            }
        }
    }

    parseDate = (dateStr) => {
        if (dateStr) {
            try {
                let m = moment(dateStr);
                if (m.isValid()) return m.toDate();
            } catch(err) { }
        }
        return null;
    }

    currentValueChanged = (e) => {
        let val = e.target.value;
        this.setState({currentValue: val});
        let dt = this.parseDate(val);
        if (dt) this.setState({currentDate: dt});
    }

    dateSelected = (dt) => {
        let strVal = dt ? moment(dt).format('M/D/YYYY') : '';
        this.setState({currentDate: dt, currentValue: strVal})
    }

    presetSelected = (e) => {
        let val = e.target.value;
        if (e.target.checked) {
            let newDate = addBusinessDays(new Date(), val);
            this.setState({
                currentValue: moment(newDate).format('M/D/YYYY'),
                currentDate: newDate,
                presetValue: val
            });
        }
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{this.props.title || "Snooze until..."}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id={"date-form"} noValidate validated={this.state.formValidated}>
                    <Form.Row>
                        <Form.Group controlId="presets">
                            <Form.Label>Presets:</Form.Label>
                            {
                                ["1", "3", "7"].map(n => <Form.Check key={n} name={"preset"} type={"radio"} id={`preset-${n}`} label={`${n} business day${n > 1 ? 's' : ''}`} value={n} checked={this.state.presetValue == n} onChange={this.presetSelected}/>)
                            }
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <InputGroup>
                            <Form.Control required={this.props.required != null ? this.props.required : true} pattern={shortDatePattern} value={this.state.currentValue || ''} onChange={this.currentValueChanged}/>
                            <InputGroup.Append>
                                <InputGroup.Text variant={"dark"}><CalendarButton selected={this.state.currentDate} onChange={this.dateSelected}/></InputGroup.Text>
                            </InputGroup.Append>
                            <Form.Control.Feedback type="invalid">
                                M/D/YYYY
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onHide}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok} disabled={this.state.currentDate == null}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}