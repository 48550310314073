import React from 'react';
import {Form} from "react-bootstrap";

/**
 * Props:
 *  name - name of input
 *  value - selected value
 *  options - array of options
 *  valueKey - in options array, key of property for option's value
 *  labelKey - in options array, key of property for option's label
 *  onChange - event handler
 *  canSelectNone [false]
 *  noneValue ['']
 *  noneLabel ['']
 *  size [sm]
 */
export class OptionalSelect extends React.Component {
    render() {
        return (
            <Form.Control as={"select"} size={this.props.size || 'sm'} custom={true} name={this.props.name} value={this.props.onChange ? this.props.value || '' : undefined} onChange={this.props.onChange} disabled={this.props.disabled} defaultValue={this.props.defaultValue} required={this.props.required}>
                {this.props.canSelectNone ?
                    <option value={this.props.noneValue || ''}>{this.props.noneLabel || ''}</option>
                        : <option value={this.props.noneValue || ''} disabled>{this.props.noneLabel || ''}</option>
                }
                {
                    this.props.options.map(s => <option key={s[this.props.valueKey]} value={s[this.props.valueKey]}>{s[this.props.labelKey]}</option>)
                }
            </Form.Control>        );
    }
}