import React from 'react';
import {GlobalContext} from "../GlobalContext";
import logo from "../cns.png";
import {Navbar, Nav, Image, NavDropdown, NavItem, Dropdown, NavLink, DropdownButton} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {RoleWrapper} from "./common/RoleWrapper";
import {UserProfileModal} from "./user/UserProfileModal";
import {ChangePasswordModal} from "./user/ChangePasswordModal";
import {Glyph} from "./common/Glyph";

export class AppHeader extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            profileModalVisible: false,
            passwordModalVisible: false
        };
    }

    showProfileModal = () => {
        this.setState({profileModalVisible: true});
    };

    hideProfileModal = () => {
        this.setState({profileModalVisible: false});
    };

    showPasswordModal = () => {
        this.setState({passwordModalVisible: true});
    };

    hidePasswordModal = () => {
        this.setState({passwordModalVisible: false});
    };

    userProfileOk = (u) => {
        this.context.apiRequest('POST', '/users', u)
            .then(this.context.refreshMyProfile)
            .then(this.hideProfileModal);
    }

    render() {
        let u = this.context.userProfile || {"firstName":"", "lastName":""};
        return (
            <Navbar id={"header"} bg="dark" expand="lg" className={"mb-3"}>
                <Navbar.Brand><Image src={logo}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <RoleWrapper roles={["Scheduling", "Supervisor", "Scheduling (Read Only)", "Authorizations", "Medical Assistant", "System Admin"]}><LinkContainer to={"/patients"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Patients</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["Scheduling", "Supervisor", "Marketing", "System Admin"]}><LinkContainer to={"/marketing"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Marketing</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["Liens", "Supervisor","System Admin"]}><LinkContainer to={"/liens"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Liens</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Authorizations"]}><LinkContainer to={"/authBoard"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Auth Board</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Supervisor","Authorizations","Scheduling","Marketing","Medical Assistant"]}><LinkContainer to={"/reports"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>Reports</Nav.Item></LinkContainer></RoleWrapper>
                        <RoleWrapper roles={["System Admin", "Supervisor"]}><LinkContainer to={"/admin"}><Nav.Item onClick={this.context.resetLogoutTimer} className={"text-light mr-3"}>System Admin</Nav.Item></LinkContainer></RoleWrapper>
                    </Nav>
                    <DropdownButton as={NavItem} className={"p-0"} menuAlign="right" id="nav-user-dropdown" title={<>{u.iconName && <Glyph name={u.iconName} className={"mr-2"} style={{fontSize: "1.2rem", color: "white"}}/>}{u.firstName} {u.lastName.substr(0,1)}.</>} variant={"secondary"}>
                        <Dropdown.Item eventKey="user-profile" onClick={this.showProfileModal}>My Profile</Dropdown.Item>
                        <Dropdown.Item eventKey="change-password" onClick={this.showPasswordModal}>Change Password</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="logout" onClick={this.context.userDidLogout}>Logout</Dropdown.Item>
                    </DropdownButton>
                </Navbar.Collapse>
                <UserProfileModal onCancel={this.hideProfileModal} show={this.state.profileModalVisible} item={u} onOk={this.userProfileOk}/>
                <ChangePasswordModal onCancel={this.hidePasswordModal} onOk={this.hidePasswordModal} show={this.state.passwordModalVisible}/>
            </Navbar>
        );
    }
}