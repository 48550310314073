import {GlobalContext} from "../../GlobalContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Button, Form, FormCheck, FormGroup, FormLabel, Modal, ModalBody, ModalFooter, Row} from "react-bootstrap";
import React from "react";
import {OptionalSelect} from "../common/OptionalSelect";

export default class WorkTaskModeModal extends React.Component {
    static contextType = GlobalContext;

    // Map of available modes by department
    modes = {
        "Auth": [
            {name: "Eligibility", value: "ELIG"},
            {name: "TCD", value: "TCD"},
            {name: "Demo", value: "AUTH"}
        ]
    };

    constructor(props) {
        super(props);
        this.state = {
            mode: "None",
            applyToAll: true,
            users: [],
            okDisabled: true
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            this.loadUsers();
            this.setState({mode: "None", applyToAll: true});
        }
    }

    componentDidMount() {
        this.loadUsers();
    }

    loadUsers = () => {
        this.context.apiRequest('GET', `/users?includeModes=true&activeOnly=true${this.props.role ? `&role=${this.props.role}` : ''}${this.props.department ? `&department=${encodeURIComponent(this.props.department)}` : ``}`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({users: resp.data});
                }
            })
    }

    toggleUser = (i) => {
        let newUsers = [...this.state.users];
        newUsers[i].selected = true;
        this.setState({users: newUsers});
    }

    ok = () => {
        this.context.apiRequest('POST', `/workTasks/applyMode`, {
            category: this.props.category,
            userIds: this.state.applyToAll ? this.state.users.map(u => u.userId) : this.state.users.filter(u => u.selected).map(u => u.userId),
            mode: this.state.mode
        }).finally(this.props.onHide)
    }

    getMode = (u) => {
        if (u.modes) {
            let mode = u.modes.find(m => m.category === this.props.category);
            if (mode) return mode.mode;
        }
        return null;
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <ModalHeader>Assign Work Mode</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <FormLabel onClick={() => this.setState({applyToAll: !this.state.applyToAll})}>Mode:</FormLabel>
                        <OptionalSelect value={this.state.mode} onChange={e => this.setState({mode: e.target.value})} options={this.modes[this.props.department]} labelKey={"name"} valueKey={"value"} canSelectNone={true} noneLabel={"None"} noneValue={"None"}/>
                    </FormGroup>
                    <FormGroup>
                        <FormCheck id={"applyToAll"} type={"switch"} label={`Apply to all ${this.props.department} members`} checked={this.state.applyToAll} onChange={e => this.setState({applyToAll: e.target.checked})}/>
                    </FormGroup>

                    {
                        !this.state.applyToAll && <FormGroup className={"ml-2"}>
                            {this.state.users.map((u, i) => {
                                    let mode = this.getMode(u);
                                    return <Form.Row key={u.userId}>
                                        <FormCheck id={`user-${u.userId}`} label={`${u.firstName} ${u.lastName}${mode ? ` (Current mode: ${mode})` : ``}`}
                                                   checked={u.selected || false} onChange={e => this.toggleUser(i)}/>
                                    </Form.Row>
                                }
                            )}
                        </FormGroup>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    {/*this.state.mode == null || (!this.state.applyToAll && !this.state.users.some(u => u.selected))*/}
                    <Button variant="dark" onClick={this.ok} disabled={this.state.mode == null || (!this.state.applyToAll && !this.state.users.some(u => u.selected))}>
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}