import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Col, Container, Row} from "react-bootstrap";
import {SchedulingToDoList} from "./SchedulingToDoList";
import {PatientSearch} from "../common/patient/PatientSearch";
import {
    Switch,
    Route, Redirect, withRouter
} from "react-router-dom";
import PatientView from "../common/patient/PatientView";
import PatientSearchResults from "../common/patient/PatientSearchResults";
import {AlertList} from "../common/AlertList";

class SchedulingHome extends React.Component {
    static contextType = GlobalContext;

    patientSelected = (patientId) => {
        this.context.resetLogoutTimer();
        this.props.history.push(`/patients/${patientId}`);
    };

    patientSearchGoClicked = (searchTerm) => {
        this.context.resetLogoutTimer();
        this.props.history.push(`/patients/search?q=${encodeURIComponent(searchTerm)}`);
    };

    render() {
        return (
            <Container fluid={true}>
                <Row>
                    <Col md={8}>
                        <PatientSearch id={"schedulingPatientSearch"} clearOnSelect={true} showNewButton={true} showGoButton={true} patientSelected={this.patientSelected} goButtonClicked={this.patientSearchGoClicked}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Switch>
                            <Route exact path={['/patients', '/']}>
                                <DefaultSchedulingHomeContent/>
                            </Route>
                            <Route exact path={'/patients/search'}>
                                <PatientSearchResults patientSelected={this.patientSelected}/>
                            </Route>
                            <Route path={'/patients/:patientId'}>
                                <PatientView/>
                            </Route>
                        </Switch>
                    </Col>
                    <Col>
                        <SchedulingToDoList patientSelected={this.patientSelected} className={"dashboardSideBox-md"}/>
                        <AlertList uri={"/alerts/scheduling"} title={"Scheduling Alerts"} className={"dashboardSideBox-md mt-2"}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function DefaultSchedulingHomeContent() {
    return <Card>
        <Card.Body>
            <h3>Patient Dashboard</h3>
            <p>To search for a patient, enter any part of the name or an Intergy ID.</p>
            <p>Click on one of the suggestions, or use the down arrow on your keyboard and hit enter
                to choose a patient.</p>
            <p>Alternatively, you can click the Go button to have the search results displayed in
                this window.</p>
        </Card.Body>
    </Card>;
}

export default withRouter(SchedulingHome);