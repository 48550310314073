import React from "react";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {OptionalSelect} from "../common/OptionalSelect";
import {shortDatePattern} from "../../utils/Dates";
import LienTaskList from "./LienTaskList";
import {GlobalContext} from "../../GlobalContext";
import LienNotesList from "./LienNotesList";
import LienTransactionList from "./LienTransactionList";
import {DateInput} from "../common/DateInput";
import {GroupSearch} from "../common/GroupSearch";
import {ProviderSearch} from "../common/ProviderSearch";
import {parsePhoneNumber} from "libphonenumber-js";
import {Glyph} from "../common/Glyph";
import {GroupDataSheetModal} from "../marketing/GroupDataSheetModal";
import moment from "moment-timezone";
import {LienDataSheetModal} from "./LienDataSheetModal";
import {formatPhone} from "../../utils/Phone";

export default class LienCaseView extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            lienCase: props.lienCase,
            validated: false,
            dirty: false,
            lastUpdate: 0,
            showDataSheet: false
        }
    }

    validateForm = () => {
        let valid = true;
        let form = document.getElementById('lien-form');
        valid &= form.checkValidity();
        this.setState({validated: true});
        return valid;
    };

    deniedReasons = [
        'DOI over one year',
        'Assault case',
        'Unable to proceed under health insurance',
        'Not accident related',
        'LVMPD',
        'Inconsistent treatment',
        'Atty on Blacklist',
        'Dual cases open, no apportionment',
        'Atty and injury out of state',
        'No response from atty',
        'Case closed',
        'Pre- existing condition',
        'Lien denied + unable to proceed under HI',
        'Limited policy limits',
        'Pt is a minor',
        'Prior neuro established',
        'Per atty, pt does not wish to proceed',
        'Per atty, pt is not a client',
        'Denied due to case moving to demand',
        'We do not treat for dx on referral'
    ].sort();

    deniedReasonChanged = (e) => {
        if (this.deniedReasons.includes(e.target.value)) {
            // selected one of the standard options - make sure the 'other' field is blank.
            let e2 = {
                target: {
                    name: "deniedReasonOther",
                    value: ''
                }
            }
            this.fieldChanged(e2);
        }
        this.fieldChanged(e);
    }

    fieldChanged = (e, overrideValue) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setState(prvState => ({
            lienCase: {
                ...prvState.lienCase,
                [fieldName]: value
            },
            dirty: true
        }))
    };

    cancel = () => {
        this.setState({
            lienCase: this.props.lienCase,
            validated: false
        });

        if (this.props.onCancel) this.props.onCancel();
    };

    save = () => {
        let lc = {
            ...this.state.lienCase,
            deniedReason: this.state.lienCase.deniedReasonOther || this.state.lienCase.deniedReason,
            deniedReasonOther: undefined
        };
        this.context.apiRequest("POST", `/liens`, lc)
            .then(d => {
                if (d.data && d.data.id) {
                    this.setState({lienCase: d.data, validated: false, dirty: false, lastUpdate: Date.now()});
                    if (this.props.onSave) {
                        this.props.onSave(d.data);
                    }
                }
            });
    };

    groupSelected = (groupId, name) => {
        this.setState(prvState => ({
            lienCase: {
                ...prvState.lienCase,
                attorneyGroupId: groupId,
                attorneyName: name
            },
            dirty: true
        }));
    };

    providerSelected = (providerId, name) => {
        this.setState(prvState => ({
            lienCase: {
                ...prvState.lienCase,
                attorneyId: providerId,
                linkedAttorneyName: name
            },
            dirty: true
        }));
    };

    deleteLienCase = () => {
        this.context.confirm(() => {
            this.context.apiRequest("DELETE", `/liens/${this.state.lienCase.id}`)
                .then(this.props.onDelete);
        }, "Are you sure you want to delete this lien case? This cannot be undone.", "Confirm Delete")
    };

    paidToOptions = [
        {label: 'CNS', value: 'CNS'},
        {label: 'SBS', value: 'SBS'}
    ];
    billingTypes = [
        {label: 'Insurance then Lien', value: 'INSURANCE'},
        {label: 'Lien Only', value: 'LIEN_ONLY'},
        {label: 'Insurance Only', value: 'INSURANCE_ONLY'}
    ];

    render() {
        let lc = this.state.lienCase;
        if (lc.deniedReason && !this.deniedReasons.includes(lc.deniedReason) && lc.deniedReason !== 'Other') {
            lc.deniedReasonOther = lc.deniedReason;
        }
        return <Container>
            <Form id={"lien-form"} noValidate validated={this.state.validated} onSubmit={this.save}>
                <Form.Row>
                    <Form.Group as={Col} md={2} controlId="status">
                        <Form.Label size="sm">
                            Status
                        </Form.Label>
                        <Form.Control required as={"select"} size={'sm'} custom={true} name={'status'} value={lc.status || 'PENDING'} onChange={this.fieldChanged}>
                            <option value="PENDING">Pending</option>
                            <option value="APPROVED_UTC">Approved/No Response</option>
                            <option value="SCHEDULED_CANCELLED">Scheduled - Cancelled/No Show</option>
                            <option value="ACTIVE">Active</option>
                            <option value="CARE_COMPLETE">Care Complete</option>
                            <option value="BILLING_PATIENT">Billing Patient</option>
                            <option value="PAID">Paid</option>
                            <option value="DENIED">Denied</option>
                            <option value="INTERPLEADER">Interpleader</option>
                            <option value="NO_SETTLEMENT">No Settlement</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md={2} controlId={"dateOfInjury"}>
                        <Form.Label size={"sm"}>
                            Date of Injury
                        </Form.Label>
                        <DateInput required size="sm" name={"dateOfInjury"}
                                      value={lc.dateOfInjury || ''}
                                      pattern={"^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/\\d{2}$"}
                                      placeholder={"mm/dd/yy"}
                                      onChange={this.fieldChanged}/>
                    </Form.Group>
                    <Form.Group as={Col} md={2} controlId={"externalCaseNumber"}>
                        <Form.Label size={"sm"}>
                            Litigation Case No.
                        </Form.Label>
                        <Form.Control size="sm" name={"externalCaseNumber"}
                                      value={lc.externalCaseNumber || ''}
                                      onChange={this.fieldChanged}/>
                    </Form.Group>
                    <Form.Group as={Col} md={3} controlId={"firstApptDateTime"}>
                        <Form.Label size={"sm"}>
                            First Appointment
                        </Form.Label>
                        <Form.Control size={"sm"} name={"firstApptDateTime"} readOnly
                                      value={lc.firstApptDateTime || ''}/>
                    </Form.Group>
                    <Col className={"text-right"} md={3}>
                        <Row>
                            <Col className={"text-center"}>
                                <Button variant={"secondary"} onClick={this.cancel}>Cancel</Button>
                                <Button variant={"dark"} className={"ml-2"} onClick={this.save} disabled={!this.state.dirty}>Save</Button>
                                {lc.id > 0 && <>
                                    <Button variant={"secondary"} className={"ml-2"} onClick={() => this.setState({showDataSheet: true})}>
                                        <Glyph name={"clipboard-data"} label={"Data Sheet"}/></Button>
                                    <LienDataSheetModal lien={lc} patient={this.props.patient} title={`Lien Data Sheet`}
                                                                  show={this.state.showDataSheet}
                                                                  close={() => this.setState({showDataSheet: false})}/>
                                </>}
                            </Col>
                        </Row>
                        {
                            lc.id > 0 &&
                            <Row>
                                <Col className={"text-center"}>
                                    <span className={"text-danger small clickable mt-3 d-inline-block"}
                                          onClick={this.deleteLienCase}>Delete Lien Case</span>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Form.Row>
                {
                    lc.status === 'DENIED' && <Form.Row>
                        <Form.Group as={Col} md={6} controlId={"deniedReason"}>
                            <Form.Label size={"sm"}>
                                Denied Reason
                            </Form.Label>
                            <Form.Control as={"select"} size={'sm'} custom={true} name={"deniedReason"} value={lc.deniedReason ? (this.deniedReasons.includes(lc.deniedReason) ? lc.deniedReason : 'Other') : ''} onChange={this.deniedReasonChanged}>
                                <option value={""} disabled={true}>Select a denial reason</option>
                                {
                                    this.deniedReasons.map((r,i) => <option key={i} value={r}>{r}</option>)
                                }
                                <option value={"Other"}>Other</option>
                            </Form.Control>
                        </Form.Group>
                        {
                            lc.deniedReason && (!this.deniedReasons.includes(lc.deniedReason) || lc.deniedReason === 'Other') && <Form.Group as={Col} md={6} controlId={"deniedReasonOther"}>
                                <Form.Label size={"sm"}>Other Denied Reason</Form.Label>
                                <Form.Control size={"sm"} name={"deniedReasonOther"} value={lc.deniedReasonOther || ''} onChange={this.fieldChanged}/>
                            </Form.Group>
                        }
                    </Form.Row>
                }
                <Form.Row>
                    <Form.Group as={Col} md={4} controlId={"attorneyName"}>
                        <Form.Label size={"sm"}>
                            Law Firm
                        </Form.Label>
                        <GroupSearch placeholder={"Search firms"} initialValue={lc.linkedGroupName || lc.attorneyName || ''} groupSelected={this.groupSelected} size={'sm'}/>
                    </Form.Group>
                    <Form.Group as={Col} md={4} controlId={"attorneyId"}>
                        <Form.Label size={"sm"}>
                            Attorney
                        </Form.Label>
                        <ProviderSearch placeholder={"Search attorneys"} initialValue={lc.linkedAttorneyFirst ? `${lc.linkedAttorneyFirst} ${lc.linkedAttorneyLast}` : ''} providerSelected={this.providerSelected} size={'sm'}/>
                    </Form.Group>
                    <Form.Group as={Col} md={4} controlId={"caseManagerName"}>
                        <Form.Label size={"sm"}>
                            Case Manager
                        </Form.Label>
                        <Form.Control size={"sm"} name={"caseManagerName"} value={lc.caseManagerName || ''} onChange={this.fieldChanged}/>
                    </Form.Group>
                </Form.Row>
                { (lc.attorneyGroupPhone || lc.attorneyGroupFax) &&
                    <Form.Row className={"mb-3"}>
                        <Col sm={4} className={"text-secondary d-flex align-items-end pb-2"}>{lc.attorneyGroupPhone && <>Attorney phone: {formatPhone(lc.attorneyGroupPhone)}</>}</Col>
                        <Col sm={4} className={"text-secondary d-flex align-items-end pb-2"}>{lc.attorneyGroupFax && <>Attorney fax: {formatPhone(lc.attorneyGroupFax)}</>}</Col>
                        <Form.Group as={Col} sm={4} controlId={"attorneyContactEmail"}>
                            <Form.Label size={"sm"}>
                                Contact Email
                            </Form.Label>
                            <Form.Control size={"sm"} name={"attorneyContactEmail"} value={lc.attorneyContactEmail || ''} onChange={this.fieldChanged}/>
                        </Form.Group>
                    </Form.Row>}
                <Form.Row>
                    <Form.Group as={Col} md={2} controlId="paidDate">
                        <Form.Label size="sm">Paid Date</Form.Label>
                        <DateInput size="sm" name={"paidDate"} value={lc.paidDate || ''} onChange={this.fieldChanged}
                                   pattern={"^(0?[1-9]|1[0-2])\\/(0?[1-9]|1\\d|2\\d|3[01])\\/\\d{2}$"}
                                   placeholder={"mm/dd/yy"}/>
                    </Form.Group>
                    <Form.Group as={Col} md={2} controlId="paidTo">
                        <Form.Label size="sm">
                            Paid To
                        </Form.Label>
                        <OptionalSelect name={"paidTo"} value={lc.paidTo || ''} options={this.paidToOptions} labelKey={"label"} valueKey={"value"} canSelectNone={true} onChange={this.fieldChanged}/>
                    </Form.Group>
                    <Form.Group as={Col} md={3} controlId="billType">
                        <Form.Label size="sm">
                            Billing Type
                        </Form.Label>
                        <OptionalSelect name={"billType"} value={lc.billType || ''} options={this.billingTypes} labelKey={"label"} valueKey={"value"} canSelectNone={true} onChange={this.fieldChanged}/>
                    </Form.Group>
                    <Col className={"small"}>
                        {
                            lc.referral && <Card>
                                <Form.Row><Col>Referral Received: {lc.referral.receivedDate}</Col></Form.Row>
                                {lc.referral.source1 &&
                                    <Form.Row><Col>Primary Source: {lc.referral.source1.firstName} {lc.referral.source1.lastName} ({lc.referral.source1.groupName})</Col></Form.Row>}
                                {lc.referral.source2 &&
                                    <Form.Row><Col>Secondary Source: {lc.referral.source2.firstName} {lc.referral.source2.lastName} ({lc.referral.source2.groupName})</Col></Form.Row>}
                            </Card>
                        }
                    </Col>
                </Form.Row>
            </Form>
            <h6 className={"mt-3"}>Tasks</h6>
            <LienTaskList lienId={lc.id} lastUpdate={this.state.lastUpdate}/>
            <h6 className={"mt-3"}>Comments</h6>
            <LienNotesList lienId={lc.id}/>
            <Row>
                <Col md={6}>
                    <h6 className={"mt-3"}>Charges</h6>
                    <LienTransactionList lienId={lc.id} txType={'charges'} txTypeName={'Charge'}/>
                </Col>
                <Col md={6}>
                    <h6 className={"mt-3"}>Payments (adjustments in italics)</h6>
                    <LienTransactionList lienId={lc.id} txType={'payments'} txTypeName={'Payment'}/>
                </Col>
            </Row>
        </Container>;
    }
}