import React from "react";
import {
    Card,
    Col,
    Dropdown,
    FormCheck,
    Row
} from "react-bootstrap";
import moment from "moment-timezone";
import {Glyph} from "../common/Glyph";
import {GlobalContext} from "../../GlobalContext";
import {RoleWrapper} from "../common/RoleWrapper";
import StringPickerModal from "../common/StringPickerModal";
import {nlToBr} from "../../utils/Strings";
import {WorkTaskReasonAndUserModal} from "./WorkTaskReasonAndUserModal";
import DatePickerModal from "../common/DatePickerModal";
import {WorkTaskSnoozeModal} from "./WorkTaskSnoozeModal";

export class WorkTaskCard extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        let t = this.props.task;
        let snoozed = false;
        if (t.snoozeUntil) {
            if (moment(t.snoozeUntil).isAfter(moment(), 'day')) {
                snoozed = true;
            }
        }
        this.state = {
            showDismissReasonModal: false,
            showPendingReasonModal: false,
            showCompleteModal: false,
            showDueDatePickerModal: false,
            showMenu: false,
            showSnoozeModal: false,
            collapsed: snoozed,
            snoozed
        }
    }
    
    assignToMe = (taskId) => {
        this.context.apiRequest('POST', `/workTasks/${(!taskId || typeof taskId === 'object') ? this.props.task.id : taskId}/assign${taskId !== this.props.task.id ? `?related=${this.props.task.id}` : ``}`)
            .then(this.props.onUpdate);
    }

    assignToOther = () => {
        this.props.onAssignToOther(this.props.task);
    }

    unassign = () => {
        let task = this.props.task;
        this.context.apiRequest('POST', `/workTasks/${task.id}/unassign`)
            .then(this.props.onUpdate);
    }

    complete = () => {
        if (this.shouldShowCompleteReasons()) {
            this.setState({showCompleteModal: true});
        } else {
            this.setTaskStatus('COMPLETE')
        }
    }

    completeAndTagUser = () => {
        if (this.props.taggableUsers) {
            this.setState({showCompleteModal: true});
        } else {
            this.setTaskStatus('COMPLETE')
        }
    }

    preDismiss = () => {
        // Show the reasons dialog
        this.setState({showDismissReasonModal: true})
    }

    prePending = () => {
        // Show the reasons dialog
        this.setState({showPendingReasonModal: true})
    }

    dismiss = (reason) => {
        this.setTaskStatus('DISMISSED', reason);
        this.setState({showDismissReasonModal: false})
    }

    resume = () => {
        this.setTaskStatus('ASSIGNED');
    }

    setPending = (reason) => {
        this.setTaskStatus('PENDING', reason);
        this.setState({showPendingReasonModal: false})
    }

    setComplete = (reason, taggedUsers) => {
        this.setTaskStatus('COMPLETE', reason, taggedUsers);
        this.setState({showCompleteModal: false})
    }

    setTaskStatus = (status, reason, taggedUsers) => {
        let task = this.props.task;
        let tags;
        if (taggedUsers) {
            tags = taggedUsers.map(u => ({taskId: task.id, tagType: 'ASSIST', referenceId: u.userId}));
        }

        this.context.apiRequest('POST', `/workTasks/${task.id}/status`, {status, reason, tags})
            .then(this.props.onUpdate);
    }

    addBusinessDays = (originalDate, numDaysToAdd) => {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = numDaysToAdd;

        const newDate = originalDate.clone();

        while (daysRemaining > 0) {
            newDate.add(1, 'days');
            if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
                daysRemaining--;
            }
        }
        return newDate;
    }

    toggleSubtask = (taskId, subtaskId) => {
        // will trigger an update to be pushed via websocket
        console.log(`toggling ${taskId}/${subtaskId}`);
        this.context.apiRequest('POST', `/workTasks/${taskId}/subtask/${subtaskId}/toggle`);
    }

    shouldShowCompleteReasons() {
        return this.props.completeReasons && this.props.completeReasons.length > 0 &&
            (!this.props.showCompleteReasonsForTaskTypes ||
                this.props.showCompleteReasonsForTaskTypes.length == 0 ||
                this.props.showCompleteReasonsForTaskTypes.includes(this.props.task.taskType));
    }

    getRelatedTaskText = (t) => {
        let str = t.taskType;
        if (t.taskType.startsWith('UPCOMING_APPT')) {
            str += ' Appt ' + moment(t.referenceDate).format("M/D/YY");
        } else {
            str += ' Due ' + moment(t.dueDate).format("M/D/YY");
        }
        return str;
    }

    dueDateChanged = (newDate) => {
        if (newDate) {
            this.context.apiRequest('PATCH', `/workTasks/${this.props.task.id}`, {
                dueDate: newDate
            })
        }
    }

    snooze = (snoozeUntil) => {
        if (snoozeUntil) {
            this.context.apiRequest('PATCH', `/workTasks/${this.props.task.id}`, {
                snoozeUntil: snoozeUntil
            }).then(() => {
                let snoozed =  moment(snoozeUntil).isAfter(moment(), 'day');
                this.setState({snoozed, collapsed: snoozed});
            })
        }
    }

    toggleStat = () => {
        this.context.apiRequest('PATCH', `/workTasks/${this.props.task.id}`, {
            stat: this.props.task.stat == 0 ? 1 : 0
        })
    }

    getPendingReasons = () => {
        if (this.props.pendingReasons) {
            let reasons = this.props.pendingReasons[this.props.task.taskType];
            if (reasons && Array.isArray(reasons)) {
                return reasons;
            } else {
                return this.props.pendingReasons['_DEFAULT'] || []
            }
        }
        return [];
    }

    getMenuItems = () => {
        let t = this.props.task;
        let items = [];

        if ((t.status === 'NEW' || t.status === 'ASSIGNED' || t.status === 'PENDING')
            && this.context.hasAnyRole(["Supervisor", "System Admin"])) {
            items.push(this.buildMenuItem('ASSIGN_TO_OTHER', "clipboard-plus", "Assign To..."));
        }

        if (t.status === 'ASSIGNED' || t.status === 'PENDING') {
            if (this.context.hasAnyRole(["Supervisor", "System Admin"])) {
                items.push(this.buildMenuItem('UNASSIGN', "clipboard-minus", "Unassign"));
            }
            items.push(this.buildMenuItem('DISMISS', "trash", "Dismiss..."));
            items.push(this.buildMenuItem('PENDING', "folder2-open", "Pending..."));
            if (t.status === 'PENDING') {
                items.push(this.buildMenuItem('SNOOZE', "hourglass", "Snooze..."));
                items.push(this.buildMenuItem('RESUME', "arrow-left-square", "Resume"));
            }
            if (this.props.taggableUsers) {
                items.push(this.buildMenuItem('COMPLETE_ASSIST', "person-check", "Complete w/ Assist"));
            }
        }
        return items;
    }

    buildMenuItem(key, icon, text) {
        return <Dropdown.Item key={key} onClick={() => this.selectMenuItem(key)}>
            <Glyph style={{fontSize: '1.0rem'}} name={icon} className={"mr-2"}/>
            {text}
        </Dropdown.Item>
    }

    selectMenuItem = (item) => {
        switch(item) {
            case 'ASSIGN_TO_OTHER':
                this.assignToOther();
                break;
            case 'UNASSIGN':
                this.unassign();
                break;
            case 'DISMISS':
                this.preDismiss();
                break;
            case 'PENDING':
                this.prePending();
                break;
            case 'RESUME':
                this.resume();
                break;
            case 'COMPLETE_ASSIST':
                this.completeAndTagUser();
                break;
            case 'SNOOZE':
                this.setState({showSnoozeModal: true});
                break;
        }
    }

    toggleCollapsed = () => {
        this.setState({collapsed: !this.state.collapsed});
    }

    render() {
        let t = this.props.task;
        let dueDate = moment(t.dueDate);
        let now = moment();
        let threshold1 = this.addBusinessDays(now, 4);
        let threshold2 = this.addBusinessDays(now, 1);
        let colorClass;
        if (dueDate.isAfter(threshold1, 'day')) {
            colorClass = 'purple';
        } else if (dueDate.isAfter(threshold2, 'day')) {
            colorClass = 'warning';
        } else {
            colorClass = 'danger';
        }

        let pendingReasons = this.getPendingReasons();

        const MenuToggle = React.forwardRef(({ children, onClick }, ref) => (
            <Glyph theRef={ref} name={"three-dots"} className={"clickable mr-3"} onClick={e => {
                e.preventDefault();
                onClick(e);
            }}/>
        ));
        const menuItems = this.getMenuItems();

        return (
            <Card key={t.id} size={"sm"} className={"mb-2"} border={colorClass}>
                {this.props.dismissReasons && this.props.dismissReasons.length > 0 &&
                    <StringPickerModal options={this.props.dismissReasons} labelKey={'label'} valueKey={'value'}
                                    canSelectNone={false} noneLabel={"Select a reason"}
                                    show={this.state.showDismissReasonModal}
                                    allowOther={true} otherKey={'Other'} title={"Dismiss Reason"}
                                    onOk={this.dismiss}
                                    onCancel={() => this.setState({showDismissReasonModal: false})}/>}
                {pendingReasons && pendingReasons.length > 0 &&
                    <StringPickerModal options={pendingReasons} labelKey={'label'} valueKey={'value'}
                                    canSelectNone={false} noneLabel={"Select a reason"}
                                    show={this.state.showPendingReasonModal}
                                    allowOther={false} title={"Pending Reason"}
                                    onOk={this.setPending}
                                    onCancel={() => this.setState({showPendingReasonModal: false})}/>}
                {(this.shouldShowCompleteReasons() || this.props.taggableUsers) &&
                    <WorkTaskReasonAndUserModal options={this.shouldShowCompleteReasons() ? this.props.completeReasons : null} labelKey={'label'} valueKey={'value'}
                                       canSelectNone={false} noneLabel={"Select a response"}
                                       show={this.state.showCompleteModal}
                                       allowOther={false} title={"Completion detail"}
                                       onOk={this.setComplete}
                                       onCancel={() => this.setState({showCompleteModal: false})}
                                       taggableUsers={this.props.taggableUsers}/>
                }
                {t.status == 'PENDING' &&
                    <WorkTaskSnoozeModal show={this.state.showSnoozeModal}
                                         onHide={() => this.setState({showSnoozeModal: false})}
                                        onOk={this.snooze}/>
                }
                <Card.Body>
                    <Row>
                        <Col md={10}>
                            {this.state.snoozed ?
                                <small className={"clickable"} onClick={this.toggleCollapsed}>
                                    <Glyph name={"hourglass"} className={"mr-2"} label={'Snoozed until '+moment(t.snoozeUntil).format('M/D/YYYY')}/>{t.title.replaceAll('\n', ' ')}
                                </small>

                                : nlToBr(t.title)}
                        </Col>
                        {
                            !this.state.collapsed &&
                            <Col className={"text-right"}>
                                <Glyph onClick={this.toggleStat} style={{fontSize: '1.25rem'}} name={"stopwatch"} className={`clickable ${t.stat ? 'font-weight-bold text-danger' : 'text-secondary'}`} label={"STAT"}/>
                            </Col>
                        }
                    </Row>
                    {
                        t.body && !this.state.collapsed && <>
                        <Row>
                            <Col><small>{nlToBr(t.body)}</small></Col>
                        </Row>
                            <Row>
                                <Col>
                                    <small>
                                        Due {dueDate.format('M/D/YY')}
                                        <Glyph name={"circle-fill"} variant={colorClass} className={"ml-2"}/>
                                        {
                                            t.status !== 'DISMISSED' && t.status !== 'COMPLETE' &&
                                            <RoleWrapper roles={["System Admin", "Supervisor"]}>
                                                <DatePickerModal currentDate={dueDate.toDate()} onOk={this.dueDateChanged} show={this.state.showDueDatePickerModal} onHide={() => this.setState({showDueDatePickerModal: false})} title={"Enter new due date"}/>
                                                <Glyph name={"pencil"} className={"ml-2 clickable"} label={"Modify Due Date"} onClick={() => this.setState({showDueDatePickerModal: true})}/>
                                            </RoleWrapper>
                                        }
                                    </small>
                                    {
                                        (t.closedReason || t.pendingReason) &&
                                        <p><small>{t.status}{t.closedReason && ` (${t.closedReason})`}{t.pendingReason && ` (${t.pendingReason})`}</small></p>
                                    }
                                </Col>
                            </Row>
                            {
                                t.status !== 'NEW' && t.subtasks && t.subtasks.map(st => <Row key={st.subtaskId}>
                                    <Col>
                                        <FormCheck type={"checkbox"}>
                                            <FormCheck.Input type={"checkbox"} checked={st.completed != null} disabled={t.status === 'DISMISSED' || t.status === 'COMPLETE'} onChange={e => this.toggleSubtask(st.taskId, st.subtaskId)}/>
                                            <FormCheck.Label><small>{st.name}</small></FormCheck.Label>
                                        </FormCheck>
                                    </Col>
                                </Row>)
                            }
                            {
                                t.helpLinks && <Row>
                                    <Col>
                                        {
                                            t.helpLinks.map(hl => <small key={hl.id}><a className={"d-block mb-1"} href={hl.url} rel="noopener noreferrer" target={"_blank"}>{hl.linkText}</a></small>)
                                        }
                                    </Col>
                                </Row>
                            }
                            {
                                t.status === 'ASSIGNED' && t.otherTasks && t.otherTasks.length > 0 && <div className={"mb-3"}>
                                    <Row><Col><small><u>Other tasks for this patient:</u></small></Col></Row>
                                    {t.otherTasks.map(ot => <Row key={ot.id} className={"pl-2"}>
                                        <Col>
                                            <small>{this.getRelatedTaskText(ot)}
                                                <Glyph name={"plus-circle"} className={"clickable ml-2"} label={"Assign to me"} onClick={() => this.assignToMe(ot.id)}/></small>
                                        </Col>
                                    </Row>)}
                                </div>
                            }
                            <Row>
                                <Col className={"text-right"}>
                                    {
                                        menuItems.length > 0 &&
                                        <Dropdown drop={"down"} className={"d-inline-block"}>
                                            <Dropdown.Toggle as={MenuToggle}/>
                                            <Dropdown.Menu>
                                                {menuItems}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }

                                    {
                                        (t.status === 'ASSIGNED' || t.status === 'PENDING') &&
                                        <Glyph style={{fontSize: '2.0rem'}} name={"star"} className={'clickable'}
                                               label={'Quick Complete'} onClick={this.complete}/>
                                    }
                                    {
                                        (t.status === 'DISMISSED' || t.status === 'COMPLETE') &&
                                        <Glyph style={{fontSize: '2.0rem'}} name={"arrow-left-square"} className={'clickable'}
                                               label={'Return to My Tasks'} onClick={this.assignToMe}/>
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
            </Card>
        );
    }
}