import React from 'react';
import {GlobalContext} from '../GlobalContext';
import {Container, Row, Col, FormGroup, Card, Form, Alert, Button, Image} from "react-bootstrap";
import logo from "../cns.png";

export class LoginForm extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            values: {
                username: "",
                password: ""
            },
            isSubmitting: false,
            isError: false,
            validated: false,
            message: "",
            announcements: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.context.apiRequest("GET", "/announcements").then(resp => {
            if (resp && resp.data) {
                this.setState({announcements: resp.data});
            }
        })
    }

    handleChange = e => this.setState({
        values: { ...this.state.values, [e.target.name]: e.target.value }
    });

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({validated: false, isError: false, message: ""});
        let form = document.getElementById("formLogin");
        let valid = form.checkValidity();
        this.setState({validated: true});
        if(!valid) return;

        this.setState({isSubmitting: true});
        const data = await this.context.apiRequest("POST", "/login", this.state.values);
        this.setState({isSubmitting: false});
        if(data && data.token) {
            this.context.userDidAuthenticate(data.token, data.userProfile);
        } else {
            this.setState({validated: false, isError: true, message: "Username and/or password is invalid."});
        }
    };

    render() {
        return (
            <Container className={`mt-5 ${this.props.authenticated || this.props.signingUp ? "d-none" : ""}`}>
                <Row>
                    <Col className="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
                        <Row>
                            <Col className="col-lg-6 col-md-8 mx-auto">
                                <Card className="rounded shadow shadow-sm" bg="secondary" text="light">
                                    <Card.Header>
                                        <h3 className="mb-0"><Image src={logo}/> Login</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form className={"form"+(this.state.validated ? " was-validated" : "")} role="form" autoComplete="off" id="formLogin"
                                              noValidate={true} method="POST" onSubmit={this.handleSubmit}>
                                            <FormGroup>
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text"
                                                       className="form-control-lg rounded-0"
                                                       name="username" required={true}
                                                       value={this.state.username}
                                                       onChange={this.handleChange}/>
                                                <Form.Control.Feedback type="invalid">Forget something?</Form.Control.Feedback>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password"
                                                       className="form-control-lg rounded-0"
                                                       name="password"
                                                       required={true} autoComplete="new-password"
                                                       value={this.state.password}
                                                       onChange={this.handleChange}/>
                                                <Form.Control.Feedback type="invalid">A password is useful if you want to log in.</Form.Control.Feedback>
                                            </FormGroup>
                                            <FormGroup className={"text-right"}>
                                                <Button type="submit" variant="dark" className={"text-center"} disabled={this.state.isSubmitting}>Login</Button>
                                            </FormGroup>
                                            <FormGroup>
                                                {(this.state.isError || this.state.isSubmitting) ?
                                                    <Alert variant={this.state.isError ? 'danger' : 'primary'}>
                                                        {this.state.isSubmitting ? "Submitting..." : this.state.message}
                                                    </Alert>
                                                    : <span/>
                                                }
                                            </FormGroup>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                {
                                    this.state.announcements && <Row className={"mt-5"}>
                                        <Col>
                                            {
                                                this.state.announcements.map((a,i) => <Alert key={i} variant={a.style || 'primary'} className={"text-center"}>{a.message}</Alert>)
                                            }
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}
