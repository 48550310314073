import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Button, Col, Form} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

export class PatientSearch extends React.Component {
    static contextType = GlobalContext;
    ref = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            options: [],
            inputValue: ''
        };
    }

    doSearch = (query) => {
        this.context.apiRequest("GET", "/patients/search?q="+encodeURIComponent(query))
            .then(d => {
                if(d.data) this.setState({options: d.data});
                this.setState({loading: false});
            })
    }

    selected = (selectionArray) => {
        if(selectionArray && selectionArray.length > 0) {
            if(this.props.clearOnSelect) {
                this.ref.current.clear();
            }
            if(this.props.patientSelected) {
                this.props.patientSelected(Number(selectionArray[0].id));
            }
        }
    };

    goClicked = () => {
        console.log(`Go clicked. term: ${this.state.inputValue}`);
        if (this.props.goButtonClicked) {
            this.props.goButtonClicked(this.state.inputValue);
        }
    };

    newPatientClicked = () => {
        this.props.patientSelected(0);
    }

    inputChanged = (val) => {
        this.setState({inputValue: val});
    }

    render() {
        return (
            <Form className={"mb-3"}>
                <Form.Row>
                    <Col md={this.props.showGoButton || this.props.showNewButton ? 8 : 12}>
                        <AsyncTypeahead id={this.props.id} size={this.props.size}
                                        labelKey={'label'}
                                        isLoading={this.state.loading}
                                        onSearch={this.doSearch}
                                        options={this.state.options}
                                        filterBy={() => true}
                                        onChange={this.selected}
                                        onInputChange={this.inputChanged}
                                        defaultInputValue={''}
                                        placeholder={'Name, Intergy ID, DOB...'}
                                        ref={this.ref}
                                        align={"left"}/>
                    </Col>
                    {
                        this.props.showGoButton || this.props.showNewButton ? <Col md={4}>
                            {
                                this.props.showGoButton ? <Button className={"mr-2"} variant={"dark"} onClick={this.goClicked} disabled={!this.state.inputValue}>Go</Button> : <span/>
                            }
                            {
                                this.props.showNewButton ? <Button className={"text-nowrap"} variant={"dark"} onClick={this.newPatientClicked}>New Patient</Button> : <span/>
                            }
                        </Col>
                        : <></>
                    }
                </Form.Row>
            </Form>
        );
    }
}