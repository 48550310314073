import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Form, Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import React from "react";
import {OptionalSelect} from "./OptionalSelect";

export default class StringPickerModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedValue: null,
            otherValue: null,
            otherKey: this.props.otherKey || 'Other'
        };
    }


    valueSelected = (e) => {
        let val = e.target.value;
        this.setState({selectedValue: val});
        if (val != this.state.otherKey) {
            this.setState({otherValue: null});
        }
    }

    ok = () => {
        let val = this.state.selectedValue;
        if (this.props.allowOther && this.state.selectedValue === this.state.otherKey && this.state.otherValue) {
            val = this.state.otherValue;
        }
        this.props.onOk(val);
    }

    render() {
        return <Modal size={this.props.size || "md"} show={this.props.show} onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title || 'Select a Value'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Col>
                            <OptionalSelect options={this.props.options}
                                            labelKey={this.props.labelKey} valueKey={this.props.valueKey}
                                            canSelectNone={this.props.canSelectNone}
                                            noneValue={this.props.noneValue}
                                            noneLabel={this.props.noneLabel}
                                            value={this.state.selectedValue} onChange={this.valueSelected}/>
                        </Col>
                    </Form.Row>
                    {
                        this.props.allowOther && this.state.selectedValue === this.state.otherKey &&
                            <Form.Row className={"mt-2"}>
                                <Col>
                                    <Form.Control placeholder={'Other value'} value={this.state.otherValue} onChange={e => this.setState({otherValue: e.target.value})}/>
                                </Col>
                            </Form.Row>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCancel}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={this.ok} disabled={!this.props.canSelectNone && this.state.selectedValue == null}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }
}