import React from 'react';
import {GlobalContext} from "../../../GlobalContext";
import {Button, Card, Col, Form, Row, Tab, Table, Tabs} from "react-bootstrap";
import {withRouter} from 'react-router-dom';
import {shortDatePattern} from '../../../utils/Dates';
import {PatientAppointmentList} from "./PatientAppointmentList";
import {PatientContactForm} from "./PatientContactForm";
import {PatientIcd10List} from "./PatientIcd10List";
import {PatientMipsList} from "./PatientMipsList";
import {PatientReferralList} from "./PatientReferralList";
import {PatientAuthList} from "./PatientAuthList";
import {PatientPreferencesForm} from "./PatientPreferencesForm";
import {DateInput} from "../DateInput";

class PatientView extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            loading: false,
            patient: null,
            validated: false,
            dobValid: true,
            auths: [],
            authsLoading: true,
            prefsFormVisible: false,
            contactFormVisible: false,
            referralsVisible: false,
            apptsLoading: true,
            apptList: [],
            icd10sLoading: true,
            icd10List: [],
            mipsLoading: true,
            mipsList: [],
            selectedTab: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let patientId = this.props.match.params.patientId;
        let prevPatientId = prevProps.match.params.patientId;
        if (!this.state.loading && prevPatientId !== patientId) {
            this.loadPatient();
        }
    }

    componentDidMount() {
        this.loadPatient();
    }

    loadPatient = () => {
        let patientId = this.props.match.params.patientId;
        if (patientId > 0) {
            this.context.apiRequest("GET", `/patients/${patientId}`)
                .then(d => {
                    if (d.data && d.data.patientId) {
                        this.setState({
                            patient: d.data, loading: false, validated: false, editing: false,
                            authsLoading: true,
                            auths: [],
                            apptsLoading: true,
                            apptList: [],
                            icd10sLoading: true,
                            icd10List: [],
                            mipsLoading: true,
                            mipsList: []
                        }, () => {
                            if (this.state.selectedTab) this.tabSelected(this.state.selectedTab);
                        });
                    }
                });
        } else {
            this.setState({
                patient: this.emptyPatient(), loading: false, validated: false, editing: true,
                authsLoading: true,
                auths: [],
                apptsLoading: true,
                apptList: [],
                icd10sLoading: true,
                icd10List: [],
                mipsLoading: true,
                mipsList: []
            });
        }
    };

    emptyPatient = () => {
        return {
            patientId: 0,
            firstName: '',
            lastName: '',
            externalPatientId: '',
            dob: '',
            gender: '',
            addr1: '',
            addr2: '',
            city: '',
            state: '',
            zip: '',
            primaryPhone: '',
            mobilePhone: '',
            shortNoticeAvailable: 0,
            preferredLocationId: null,
            preferredTimeOfDay: null,
            availableMonday: 1,
            availableTuesday: 1,
            availableWednesday: 1,
            availableThursday: 1,
            availableFriday: 1
        }
    }

    edit = () => {
        this.setState({editing: true});
    };

    cancelEdit = () => {
        this.loadPatient();
        this.setState({editing: false});
    };

    save = (e) => {
        if (e) e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        let p = this.state.patient;
        this.context.apiRequest("POST", `/patients/${p.patientId}`, p)
            .then(d => {
                if (d.data && d.data.patientId) {
                    this.setState({patient: d.data, loading: false, editing: false, validated: false});
                }
            });
    };

    validateForm = () => {
        let valid = true;
        let form = document.getElementById('patient-form');
        valid &= form.checkValidity();
        valid &= document.getElementById('patient-contact-form').checkValidity();
        this.setState({validated: true});
        return valid;
    };

    patientFieldChanged = (e, overrideValue) => {
        let fieldName = e.target.name;
        let value = overrideValue === undefined ? e.target.value : overrideValue;
        this.setState(prvState => ({
            patient: {
                ...prvState.patient,
                [fieldName]: value
            }
        }))
    };

    loadSubList = (path, dataField, loadingFlagField, force) => {
        if(force || this.state[loadingFlagField]) {
            this.setState({[loadingFlagField]: true})
            let patientId = this.props.match.params.patientId;
            this.context.apiRequest('GET', `/patients/${patientId}/${path}`)
                .then(d => {
                    if (d.data) {
                        this.setState({[dataField]: d.data});
                    }
                })
                .finally(() => this.setState({[loadingFlagField]: false}));
        }
    }

    tabSelected = (key) => {
        this.setState({selectedTab: key});
        switch(key) {
            case 'authorizations':
                this.loadSubList('authorizations', 'auths', 'authsLoading');
                break;
            case 'appointments':
                this.loadSubList('appointments', 'apptList', 'apptsLoading');
                break;
            case 'icd10s':
                this.loadSubList('icd10s', 'icd10List', 'icd10sLoading');
                break;
            case 'mips':
                this.loadSubList('mips', 'mipsList', 'mipsLoading');
                break;
            default:
        }
    };

    render() {
        let p = this.state.patient;
        return (
            <Card>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col xs={8}>
                                <h4>{p ? `${p.firstName} ${p.lastName}` : `Loading...`}</h4>
                            </Col>
                            <Col className={"text-right"}>
                                {this.state.editing ?
                                    <Button variant={"secondary"} onClick={this.cancelEdit}>Cancel</Button> : <span/>}
                                {this.state.editing ?
                                    <Button variant={"dark"} className={"ml-2"} onClick={this.save}>Save</Button> :
                                    <span/>}
                                {this.state.editing ? <span/> :
                                    <Button variant={"dark"} onClick={this.edit}>Edit</Button>}
                            </Col>
                        </Row>
                    </Card.Title>
                    {p ? (
                        <div>
                            <Form id={"patient-form"} noValidate validated={this.state.validated} onSubmit={this.save}>
                                <Form.Row>
                                    <Form.Group as={Col} xs={3} controlId="externalPatientId">
                                        <Form.Label size="sm">
                                            Account Number
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"externalPatientId"}
                                                      value={p.externalPatientId}
                                                      onChange={this.patientFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={3} controlId="externalPatientId">
                                        <Form.Label size="sm">
                                            Patient Number
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"externalPatientId2"}
                                                      value={p.externalPatientId2}
                                                      onChange={this.patientFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>

                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="firstName">
                                        <Form.Label size="sm">
                                            First name
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"firstName"} value={p.firstName}
                                                      onChange={this.patientFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="lastName">
                                        <Form.Label size="sm">
                                            Last name
                                        </Form.Label>
                                        <Form.Control required size="sm" name={"lastName"} value={p.lastName}
                                                      onChange={this.patientFieldChanged}
                                                      disabled={!this.state.editing}/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="dob">
                                        <Form.Label size="sm">
                                            DOB
                                        </Form.Label>
                                        <DateInput required size="sm"
                                                      name={"dob"}
                                                      pattern={shortDatePattern}
                                                      value={p.dob}
                                                      onChange={this.patientFieldChanged}
                                                      disabled={!this.state.editing}/>
                                        <Form.Control.Feedback type={"invalid"}>MM/DD/YYYY e.g
                                            02/28/2021</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="gender">
                                        <Form.Label size="sm">
                                            Gender
                                        </Form.Label>
                                        <Form.Control as={"select"} size="sm" name={"gender"} value={p.gender || ''}
                                                      onChange={this.patientFieldChanged} disabled={!this.state.editing}
                                                      custom={true}>
                                            <option value={"M"}>Male</option>
                                            <option value={"F"}>Female</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Label size="sm">
                                            Insurance Plans
                                        </Form.Label>
                                        <Table striped size={"sm"}>
                                            {
                                                p.insurances && p.insurances.length > 0 ? <>
                                                    <thead>
                                                    <tr>
                                                        <th>Plan Code</th>
                                                        <th>Carrier Code</th>
                                                        <th>Plan Name</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        p.insurances.map(i => <tr key={i.id}>
                                                            <td>{i.planCode}</td>
                                                            <td>{i.companyCode}</td>
                                                            <td>{i.companyName}</td>
                                                        </tr>)
                                                    }
                                                    </tbody>
                                                </> : <tbody><tr><td>None</td></tr></tbody>
                                            }
                                            <thead></thead>
                                        </Table>
                                    </Col>
                                </Form.Row>
                            </Form>

                            <Tabs id="patientTabs" defaultActiveKey={"referrals"} onSelect={this.tabSelected} className={"mt-3"}>
                                {p.patientId && this.context.hasAnyRole(["Scheduling", "Medical Assistant", "Scheduling (Read Only)", "System Admin"]) ?
                                    <Tab eventKey="referrals" title="Referrals" className={"formTab"}>
                                        <PatientReferralList patientId={p.patientId} modalSize={"xl"} extraData={{patient: p}}/>
                                    </Tab> : <span/>
                                }
                                {p.patientId && this.context.hasAnyRole(["Authorizations", "Medical Assistant", "System Admin"]) ?
                                    <Tab eventKey="authorizations" title="Auth" className={"formTab"}>
                                        <PatientAuthList auths={this.state.auths} loading={this.state.authsLoading} patientId={p.patientId} modalSize={"lg"}
                                                         triggerReload={() => this.loadSubList('authorizations', 'auths', 'authsLoading', true)} />
                                    </Tab> : <span/>
                                }
                                <Tab eventKey="contactInfo" title="Contact" className={"formTab"}>
                                    <PatientContactForm patient={this.state.patient} patientFieldChanged={this.patientFieldChanged} editing={this.state.editing} validated={this.state.validated}/>
                                </Tab>
                                <Tab eventKey="preferences" title="Preferences" className={"formTab"}>
                                    <PatientPreferencesForm patient={this.state.patient} patientFieldChanged={this.patientFieldChanged} editing={this.state.editing} validated={this.state.validated}/>
                                </Tab>
                                {p.patientId && this.context.hasAnyRole(["Scheduling", "Medical Assistant", "System Admin"]) ?
                                    <Tab eventKey="appointments" title="Appts" className={"formTab"}>
                                        <PatientAppointmentList apptList={this.state.apptList} loading={this.state.apptsLoading}/>
                                    </Tab> : <span/>
                                }
                                {p.patientId && this.context.hasAnyRole(["Scheduling", "Medical Assistant", "System Admin"]) ?
                                    <Tab eventKey="icd10s" title="ICD10s" className={"formTab"}>
                                        <PatientIcd10List icd10List={this.state.icd10List} loading={this.state.icd10sLoading}/>
                                    </Tab> : <span/>
                                }
                                {p.patientId && this.context.hasAnyRole(["Scheduling", "Medical Assistant", "System Admin"]) ?
                                    <Tab eventKey="mips" title="MIPS" className={"formTab"}>
                                        <PatientMipsList mipsList={this.state.mipsList} loading={this.state.mipsLoading}/>
                                    </Tab> : <span/>
                                }
                                </Tabs>
                        </div>
                    ) : <span/>}
                </Card.Body>
            </Card>
        );
    }
}

export default withRouter(PatientView);