import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Alert, Badge, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {WorkTaskCard} from "./WorkTaskCard";
import {RoleWrapper} from "../common/RoleWrapper";
import SelectUserModal from "../common/SelectUserModal";
import {Glyph} from "../common/Glyph";
import {formatDateTime} from "../../utils/Dates";
import {CollapsibleCardList} from "./CollapsibleCardList";
import WorkTaskModeModal from "./WorkTaskModeModal";
import WorkTaskStatsModal from "./WorkTaskStatsModal";
import WorkTaskSearchModal from "./WorkTaskSearchModal";

export class WorkTaskBoard extends React.Component {
    static contextType = GlobalContext;

    // WebSocket
    ws = null;
    wsKeepAlive = true;

    constructor(props) {
        super(props);
        this.state = {
            backlog: [],
            userTasks: [],
            pendingUserTasks: [],
            completedTasks: [],
            teamTasks: [],
            deptUsers: [],
            teamStats: [],
            lastImport: null,
            showUsersModal: false,
            selectedTask: null,
            dataTimestamp: 0,
            showModeDialog: false,
            showStatsDialog: false,
            showSearchDialog: false,
            loading: false,
            backlogCount: 0
        };
        this.wsReconnect();
    }

    wsReconnect = () => {
        try {
            const port = process && (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? '3000' : window.location.port;
            this.ws = new WebSocket(`${window.location.protocol.toLowerCase().startsWith('https') ? 'wss' : 'ws'}://${window.location.hostname}:${port}`);

            // Listen for messages
            this.ws.addEventListener("message", event => {
                if (event.data) {
                    let msg = JSON.parse(event.data);
                    switch (msg.event) {
                        case `workTask.${this.props.category}.fullRefresh`:
                            this.refresh();
                            break;
                        case `workTask.${this.props.category}.update`:
                            this.updateTasks(msg.tasks);
                            break;
                        case `workTaskBoard.${this.props.category}.teamStats.replace`:
                            this.setState({teamStats: msg.stats});
                            break;
                        case 'bomModeChanged':
                            this.setState({bomMode: msg.value}, this.refresh);
                            break;
                    }
                }
            });

            // If socket is closed due to error, reconnect in 5 sec
            this.ws.addEventListener("error", event => {
                console.log("WebSocket error: ", event);
                if (this.wsKeepAlive) {
                    window.setTimeout(this.wsReconnect, 3000);
                }
            });
            this.ws.addEventListener("close", event => {
                console.log("WebSocket closed");
                if (this.wsKeepAlive) {
                    window.setTimeout(this.wsReconnect, 1000);
                }
            });
        } catch(err) {
            console.log("Error opening WebSocket: ", err);
            // try again in 5 sec
            if (this.wsKeepAlive) {
                window.setTimeout(this.wsReconnect, 3000);
            }
        }
    }

    componentDidMount() {
        this.refresh();
        this.context.apiRequest('GET', `/users?department=${encodeURIComponent(this.props.department)}&activeOnly=true`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({deptUsers: resp.data});
                }
            })
    }

    componentWillUnmount() {
        if (this.ws) {
            try {
                this.wsKeepAlive = false; // Disable auto reconnect
                this.ws.close();
            } catch(err) {
                console.log(`Error while closing websocket: ${err}`);
            }
        }
    }

    BACKLOG = 1;
    USERTASKS = 2;
    PENDINGUSERTASKS = 5;
    COMPLETED = 3;
    TEAM = 4;

    updateTasks = (tasks) => {
        if (tasks && tasks.length > 0) {
            tasks.forEach(t => t.userFullName = `${t.firstName} ${t.lastName}${t.mode && t.mode !== 'None' ? ` [${t.mode}]` : ``}`);
            let backlog = null;
            let userTasks = null;
            let pendingUserTasks = null;
            let completedTasks = null;
            let teamTasks = null;

            // For each task, see where it currently is vs. where it belongs and add/remove as needed.
            for (const task of tasks) {
                let currentLocs = this.isWhere(task);
                let belongsLocs = this.belongsWhere(task);

                // Only move/update if we already had it on the board.
                // This prevents updates from users in different modes from pushing cards onto the boards
                // of users who wouldn't normally see those cards.
                if (currentLocs.length > 0) {
                    for (const currentLoc of currentLocs) {
                        if (!belongsLocs.includes(currentLoc)) {
                            // Shouldn't be there - remove
                            switch(currentLoc) {
                                case this.BACKLOG:
                                    backlog = this.removeTask(backlog, this.state.backlog, task);
                                    break;
                                case this.USERTASKS:
                                    userTasks = this.removeTask(userTasks, this.state.userTasks, task);
                                    break;
                                case this.PENDINGUSERTASKS:
                                    pendingUserTasks = this.removeTask(pendingUserTasks, this.state.pendingUserTasks, task);
                                    break;
                                case this.COMPLETED:
                                    completedTasks = this.removeTask(completedTasks, this.state.completedTasks, task);
                                    break;
                                case this.TEAM:
                                    teamTasks = this.removeTask(teamTasks, this.state.teamTasks, task);
                                    break;
                            }
                        }
                    }

                    // Add task to lists where absent or update if already exists
                    for (const belongsLoc of belongsLocs) {
                        switch(belongsLoc) {
                            case this.BACKLOG:
                                backlog = this.addOrReplaceTask(backlog, this.state.backlog, task);
                                break;
                            case this.USERTASKS:
                                userTasks = this.addOrReplaceTask(userTasks, this.state.userTasks, task);
                                break;
                            case this.PENDINGUSERTASKS:
                                pendingUserTasks = this.addOrReplaceTask(pendingUserTasks, this.state.pendingUserTasks, task);
                                break;
                            case this.COMPLETED:
                                completedTasks = this.addOrReplaceTask(completedTasks, this.state.completedTasks, task);
                                break;
                            case this.TEAM:
                                teamTasks = this.addOrReplaceTask(teamTasks, this.state.teamTasks, task);
                                break;
                        }
                    }
                }
            }

            // For each list, default to the current state and that one won't update since it's the same reference.
            this.setState({
                backlog: backlog ? backlog.sort(this.backlogCompare) : this.state.backlog,
                userTasks: userTasks ? userTasks.sort(this.userCompare) : this.state.userTasks,
                pendingUserTasks: pendingUserTasks ? pendingUserTasks.sort(this.userCompare) : this.state.pendingUserTasks,
                completedTasks: completedTasks || this.state.completedTasks,
                teamTasks: teamTasks ? teamTasks.sort(this.teamCompare) : this.state.teamTasks
            });
        }

    }

    // Compare functions for sorting each of the lists
    backlogCompare = (a, b) => { // Due date, then appt date/time
        let result = a.dueDate.localeCompare(b.dueDate);
        if (result == 0 && a.appointmentDateTime && b.appointmentDateTime) {
            // both have appt date/time
            result = a.appointmentDateTime.localeCompare(b.appointmentDateTime);
        } else if (!a.appointmentDateTime && !b.appointmentDateTime) {
            // both null
            result = 0;
        } else {
            // one null
            result = a.appointmentDateTime ? 1 : -1;
        }
        return result;
    }
    userCompare = (a, b) => { // reference date, then id
        let result = a.referenceDate.localeCompare(b.referenceDate);
        if (result == 0) {
            result = String(a.id).localeCompare(String(b.id));
        }
        return result;
    }
    teamCompare = (a, b) => { // username, then dueDate, then id
        let result = a.username.localeCompare(b.username);
        if (result == 0) {
            result = a.dueDate.localeCompare(b.dueDate);
        }
        if (result == 0) {
            result = String(a.id).localeCompare(String(b.id));
        }
        return result;
    }

    /**
     * Removes the given item from the given array, initializing the array from state if needed.
     * @param arr the temp array
     * @param stateArr the state array to copy into the temp array if temp array hasn't been initialized yet.
     * @param task
     * @returns {*[]}
     */
    removeTask = (arr, stateArr, task) => {
        if (arr == null) arr = [...stateArr];
        let index = arr.findIndex(t => t.id == task.id);
        if (index >= 0) arr.splice(index, 1);
        if (stateArr == this.state.backlog) this.setState({backlogCount: this.state.backlogCount-1});
        return arr;
    }

    /**
     * Adds the given item to the given array, initializing the array from state if needed.
     * @param arr the temp array
     * @param stateArr the state array to copy into the temp array if temp array hasn't been initialized yet.
     * @param task
     * @returns {*[]}
     */
    addOrReplaceTask = (arr, stateArr, task) => {
        if (!arr) arr = [...stateArr];
        let index = arr.findIndex(t => t.id == task.id);
        if (index >= 0) {
            arr[index] = task;
        } else {
            arr.unshift(task);
            if (stateArr == this.state.backlog) this.setState({backlogCount: this.state.backlogCount+1});
        }
        return arr;
    }

    isWhere = (task) => {
        let locs = [];
        if (this.state.backlog.find(t => t.id == task.id)) locs.push(this.BACKLOG);
        if (this.state.userTasks.find(t => t.id == task.id)) locs.push(this.USERTASKS);
        if (this.state.pendingUserTasks.find(t => t.id == task.id)) locs.push(this.PENDINGUSERTASKS);
        if (this.state.completedTasks.find(t => t.id == task.id)) locs.push(this.COMPLETED);
        if (this.state.teamTasks.find(t => t.id == task.id)) locs.push(this.TEAM);
        return locs;
    }

    belongsWhere = (t) => {
        let supervisor = this.context.hasAnyRole(['System Admin', 'Supervisor']);
        let locs = [];
        if (t.status == 'NEW') {
            locs.push(this.BACKLOG);
        } else if (supervisor && (t.status == 'ASSIGNED' || t.status == 'PENDING')) {
            locs.push(this.TEAM);
        }
        if (t.assigneeUserId == this.context.userProfile.userId) {
            if (t.status == 'ASSIGNED') {
                locs.push(this.USERTASKS);
            }
            else if (t.status == 'PENDING') {
                locs.push(this.PENDINGUSERTASKS);
            }
            else if (t.status == 'COMPLETE' || t.status == 'DISMISSED') {
                locs.push(this.COMPLETED);
            }
        }

        return locs;
    }

    sortQueues = () => {

    }

    takeNext = () => {
        let task = this.state.backlog[0];
        this.context.apiRequest('POST', `/workTasks/${task.id}/assign`);
    }

    BACKLOG_LIMIT = 100;

    refresh = () => {
        // Use state to only allow requests one at a time
        if (this.state.loading) return;
        this.setState({loading: true}, () => {
            let supervisor = this.context.hasAnyRole(['System Admin', 'Supervisor']);
            this.context.apiRequest('GET', `/workTasks?category=${this.props.category || ''}${supervisor ? '&includeTeam=true' : '&backlogLimit='+this.BACKLOG_LIMIT}`)
                .then(resp => {
                    if (resp.data) {
                        if (resp.data.teamTasks) {
                            for (const task of resp.data.teamTasks) {
                                task.userFullName = `${task.firstName} ${task.lastName}${task.mode && task.mode !== 'None' ? ` [${task.mode}]` : ``}`;
                            }
                        }
                        this.setState({
                            ...resp.data
                        });
                    }
                })
                .finally(() => this.setState({loading: false}));
        });
    }

    showUsersModal = (task) => {this.setState({showUsersModal: true, selectedTask: task});}
    hideUsersModal = () => {this.setState({showUsersModal: false, selectedTask: null});}

    userSelected = (user) => {
        let task = this.state.selectedTask;
        if (task) {
            this.context.apiRequest('POST', `/workTasks/${task.id}/assign`, {userId: user.userId});
        }
        this.hideUsersModal();
    }

    dueTodayStr = () => {
        let stats = this.state.dueTodayStats || {};
        let arr = [];
        if (stats.NEW) arr.push('Unassigned: '+stats.NEW);
        if (stats.ASSIGNED) arr.push('Assigned: '+stats.ASSIGNED);
        if (stats.PENDING) arr.push('Pending: '+stats.PENDING);
        return arr.length == 0 ? 'Caught up!' : arr.join(' / ');
    }

    render() {
        let otherUsers = this.state.deptUsers.filter(u => u.userId != this.context.userProfile.userId);
        return (
            <Container fluid className={'work-task-board'}>
                <SelectUserModal department={this.props.department} show={this.state.showUsersModal} onOk={this.userSelected} onCancel={this.hideUsersModal} role={"Authorizations"}/>
                {(this.state.lastImport || this.state.teamStats) && <Alert variant={"secondary"}>
                    <Row>
                        <Col sm={"auto"}>
                            <small>Today's Leaders:</small>
                        </Col>
                        <Col>
                            {
                                this.state.teamStats.map((s,i) => <Badge key={s.userId} style={s.customColor && {backgroundColor: s.customColor, color: 'white'}} variant={s.customColor ? null : "dark"} className={"ml-2"}>
                                    #{i+1} {s.iconName && <Glyph name={s.iconName}/>} {s.firstName} {s.lastName.charAt(0)}: {s.closedToday}
                                </Badge>)
                            }
                        </Col>
                        <Col className={"text-right"} sm={"auto"}>
                            {this.state.loading && <Spinner animation={"border"} size={"sm"}/>}
                            {
                                this.state.lastImport && <small className={"text-secondary ml-3"}>Last import: {formatDateTime(this.state.lastImport)}</small>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                            {this.state.dueTodayStats && <small>Team Tasks Due Today: {this.dueTodayStr()}</small>}
                        </Col>
                        <Col className={"text-center"}></Col>
                        <Col className={"text-right"} sm={"auto"}>
                            {this.state.currentMode && this.state.currentMode != "None" && <small>Work Mode: <b>{this.state.currentMode}</b></small>}
                            <RoleWrapper roles={["Supervisor", "System Admin"]}>
                                <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showSearchDialog: true})}>Search</Button>
                                <WorkTaskSearchModal category={this.props.category} show={this.state.showSearchDialog} onHide={() => this.setState({showSearchDialog: false})}/>
                                <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showModeDialog: true})}>Assign Modes</Button>
                                <WorkTaskModeModal category={this.props.category} department={this.props.department} show={this.state.showModeDialog} onHide={() => this.setState({showModeDialog: false})}/>
                            </RoleWrapper>
                            <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showStatsDialog: true})}>Stats</Button>
                            <WorkTaskStatsModal category={this.props.category} department={this.props.department} taskTypes={['UPCOMING_APPT_ELIG']} show={this.state.showStatsDialog} onHide={() => this.setState({showStatsDialog: false})}/>
                        </Col>
                    </Row>
                </Alert>}
                <Row>
                    <Col md={3} style={{maxHeight: 'calc(100vh - 200px', overflowY: 'auto'}}>
                        <Row>
                            <Col>
                                <h4 className={"d-inline-block"}>Backlog
                                    ({this.state.backlogCount || this.state.backlog.length})</h4>
                            </Col>
                            {this.state.backlog.length > 0 && <Col className={"text-right"}>
                                <Button size={"sm"} variant={"success"} className={"float-right"}
                                        onClick={this.takeNext}
                                        disabled={this.state.userTasks && this.state.userTasks.length > 4}>
                                    Will Work On It <Glyph name={"arrow-right"}/>
                                </Button></Col>}
                        </Row>
                        {
                            this.state.backlog.map(t => <WorkTaskCard key={t.id} task={t} onAssignToOther={this.showUsersModal}/>)
                        }
                    </Col>
                    <Col md={3} style={{maxHeight: 'calc(100vh - 200px', overflowY: 'auto'}}>
                        <h4>Assigned To Me ({this.state.userTasks.length})</h4>
                        {
                            this.state.userTasks.map(t => <WorkTaskCard key={t.id} task={t}
                                                                        pendingReasons={this.props.pendingReasons}
                                                                        dismissReasons={this.props.dismissReasons}
                                                                        completeReasons={this.props.completeReasons}
                                                                        showCompleteReasonsForTaskTypes={this.props.showCompleteReasonsForTaskTypes}
                                                                        onAssignToOther={this.showUsersModal}
                                                                        taggableUsers={otherUsers}
                            />)
                        }
                    </Col>
                    <Col md={3} style={{maxHeight: 'calc(100vh - 200px', overflowY: 'auto'}}>
                        {
                            this.state.pendingUserTasks && this.state.pendingUserTasks.length > 0 &&
                            <CollapsibleCardList tasks={this.state.pendingUserTasks}
                                                 className={"mb-3"}
                                                 groupBy={"pendingReason"}
                                                 sortGroupsBy={"pendingReason"}
                                                 header={`Pending (${this.state.pendingUserTasks.length})`}
                                                 pendingReasons={this.props.pendingReasons}
                                                 dismissReasons={this.props.dismissReasons}
                                                 completeReasons={this.props.completeReasons}
                                                 showCompleteReasonsForTaskTypes={this.props.showCompleteReasonsForTaskTypes}
                                                 onAssignToOther={this.showUsersModal}
                                                 taggableUsers={otherUsers}
                            />
                        }
                        <h4>Completed Today ({this.state.completedTasks.length})</h4>
                        {
                            this.state.completedTasks.map(t => <WorkTaskCard key={t.id} task={t}/>)
                        }
                    </Col>
                    <Col md={3} style={{maxHeight: 'calc(100vh - 200px', overflowY: 'auto'}}>
                        <RoleWrapper roles={["System Admin", "Supervisor"]}>
                            {
                                <CollapsibleCardList tasks={this.state.teamTasks}
                                                     groupBy={"userFullName"}
                                                     sortGroupsBy={"userFullName"}
                                                     sortCardsBy={"status"}
                                                     header={`All Assigned Tasks (${this.state.teamTasks.length})`}
                                                     pendingReasons={this.props.pendingReasons}
                                                     dismissReasons={this.props.dismissReasons}
                                                     completeReasons={this.props.completeReasons}
                                                     showCompleteReasonsForTaskTypes={this.props.showCompleteReasonsForTaskTypes}
                                                     onAssignToOther={this.showUsersModal}
                                                     taggableUsers={otherUsers}
                                />
                            }
                        </RoleWrapper>
                    </Col>
                </Row>
            </Container>
        );
    }
}