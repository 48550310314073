import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {ButtonGroup, Col, DropdownButton, FormControl, Modal, ModalBody, Row, Table, Form} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment-timezone";
import DropdownItem from "react-bootstrap/DropdownItem";
import Cookies from "universal-cookie";
import {RoleWrapper} from "../common/RoleWrapper";

export default class WorkTaskStatsModal extends React.Component {
    static contextType = GlobalContext;

    filters = [
        {
            name: "Eligibility (General)",
            taskTypes: ["UPCOMING_APPT_ELIG"],
            apptReasons: null,
            numDays: 4,
            weekdaysOnly: true,
            dateCol: 'referenceDate',
            progressDateLabel: 'Appointment Date'
        },
        {
            name: "Auth (General)",
            taskTypes: ["EHR_TASK"],
            apptReasons: null,
            numDays: 7,
            weekdaysOnly: true,
            dateCol: 'dueDate',
            progressDateLabel: 'Due Date'
        },
        {
            name: "Eligibility (TCD)",
            taskTypes: ["UPCOMING_APPT_ELIG"],
            apptReasons: ['TCD', 'BUB', 'HDT', 'TCDVMR', 'CAR', 'MON'],
            numDays: 7,
            weekdaysOnly: false,
            dateCol: 'referenceDate',
            progressDateLabel: 'Appointment Date'
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            selectedFilter: this.filters[0],
            teamSize: 1,
            minTeamSize: 1
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!prevProps.show && this.props.show) || prevState.selectedFilter.name !== this.state.selectedFilter.name) {
            this.loadData();
        }
    }

    loadData = () => {
        const cookies = new Cookies();
        let teamSizeCookie = cookies.get("authBoardStats_TeamSize");
        if (teamSizeCookie && !isNaN(teamSizeCookie)) {
            this.setState({teamSize: parseInt(teamSizeCookie)});
        }
        let taskTypes = '';
        let apptReasons = '';
        let f = this.state.selectedFilter;
        if (f.taskTypes) taskTypes = '&' + f.taskTypes.map(t => `taskTypes=${t}`).join('&');
        if (f.apptReasons) apptReasons = '&' + f.apptReasons.map(t => `apptReasons=${t}`).join('&');
        this.context.apiRequest('GET', `/workTasks/taskStats?category=${this.props.category}&department=${this.props.department}&weekdaysOnly=${f.weekdaysOnly}&dateCol=${f.dateCol}&numDays=${f.numDays || 4}${taskTypes}${apptReasons}`)
            .then(d => {
                if (d && d.data) {
                    let newTeamSize = this.state.teamSize;
                    let minTeamSize = d.data.productivityStats.activeTodayTeamSize;
                    if (minTeamSize && minTeamSize > newTeamSize) newTeamSize = minTeamSize;
                    this.setState({data: d.data, teamSize: newTeamSize, minTeamSize: minTeamSize});
                }
            })
    }

    buildUserTotalRows = () => {
        let d = this.state.data;
        let ps = d.progressStats;
        let numDays = ps.numDays;
        let userRowCount = d.teamSize+1; // User rows = team size + 1 because of 'System'
        if (ps && ps.userStats) {
            let rows = [];
            for (let i=0; i<userRowCount; i++) {
                let cols = [];
                for (let j=0; j<numDays; j++) {
                    let index = j * userRowCount + i;
                    cols.push(ps.userStats[index].totalComplete);
                }
                rows.push(<tr key={ps.userStats.userName+i}>
                    <td>{ps.userStats[i].displayName}</td>
                    {cols.map((c,k) => <td key={i*k} className={"task-stats-cell"}>{c}</td>)}
                </tr>)
            }
            return rows;
        }
        return <></>;
    }


    selectFilter = (f) => {
        this.setState({selectedFilter: f});
    }

    teamSizeChanged = (e) => {
        try {
            let val = parseInt(e.target.value);
            if (!isNaN(val) && val >= 1 && val <= 50) {
                this.setState({teamSize: val});
                // Persist in a cookie
                const cookies = new Cookies();
                cookies.set("authBoardStats_TeamSize", e.target.value, {path: "/"});
            }
        } catch(err) {
            console.warn(err);
        }
    }

    render() {
        let d = this.state.data;
        let ps = d.progressStats;
        let pd = d.productivityStats;
        let ut = d.currentUserCompletedTasks;
        let filter = this.state.selectedFilter;

        return <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"} scrollable={true}>
            <ModalHeader closeButton>{this.props.department} Team Stats</ModalHeader>
            <ModalBody>
                {ps && ps.totals && <>
                    <h5 className={"float-left"}>Progress</h5>
                    <DropdownButton as={ButtonGroup} id={"stat-filter"} size="sm" title={this.state.selectedFilter.name} className={"float-right"} variant={"dark"}>
                        {
                            this.filters.map((f,i) => <DropdownItem key={i} eventKey={f.name} onClick={() => this.selectFilter(f)}>{f.name}</DropdownItem>)
                        }
                    </DropdownButton>
                    <Table size={"sm"} striped borderless className={"mb-5"}>
                    <thead>
                        <tr>
                            <th></th>
                            <th colSpan={ps.numDays || 4} className={"text-center"}>{filter.progressDateLabel}</th>
                        </tr>
                        <tr>
                            <th></th>
                            {ps.totals.map((r,i) => <th key={i} className={"text-center"}>{moment(r[filter.dateCol]).format('M/D')}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={"font-weight-bold"}>
                            <td>Total Tasks</td>
                            {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks}</td>)}
                        </tr>
                        <tr>
                            <td></td>
                            <td colSpan={ps.numDays || 4} className={"text-center font-weight-bold"}>Completed Per Employee</td>
                        </tr>
                        {this.buildUserTotalRows()}
                        <tr>
                            <td></td>
                            <td colSpan={ps.numDays || 4} className={"text-center font-weight-bold"}>Team Totals</td>
                        </tr>
                        <tr className={"font-weight-bold"}>
                            <td>Total Complete</td>
                            {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalComplete}</td>)}
                        </tr>
                        <tr className={"font-weight-bold"}>
                            <td>Completed Today</td>
                            {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalCompletedToday}</td>)}
                        </tr>
                        <tr className={"font-weight-bold"}>
                            <td>To Do</td>
                            {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks-r.totalComplete}</td>)}
                        </tr>
                        <tr className={"font-weight-bold"}>
                            <td>% Complete</td>
                            {ps.totals.map((r,i) => <td key={i} className={"task-stats-cell"}>{r.totalTasks > 0 ? ((r.totalComplete/r.totalTasks)*100).toFixed(1) : '0.0'}%</td>)}
                        </tr>
                    </tbody>
                </Table></>}

                {pd && this.state.selectedFilter.name == "Eligibility (General)" && <>
                    <Row>
                        <Col>
                            <h5>Daily Productivity</h5>
                        </Col>
                        <Col className={"justify-content-end"} md={3}>
                            <Form.Row>
                                <Form.Label column="sm" lg={6}>
                                    Team size:
                                </Form.Label>
                                <Col>
                                    <RoleWrapper roles={"Supervisor"}>
                                        <Form.Control type={"number"} className={"d-inline ml-2"}
                                                      size={"sm"}
                                                      min={1}
                                                      max={"50"}
                                                      value={this.state.teamSize}
                                                      onChange={this.teamSizeChanged}/>
                                    </RoleWrapper>
                                    <RoleWrapper inverse={true} roles={"Supervisor"}>
                                        {this.state.teamSize}
                                    </RoleWrapper>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Row>
                    <Table striped borderless size={"sm"} className={"mb-5"}>
                        <thead>
                        <tr>
                            <th></th>
                                <th className={"text-center"}>Goal</th>
                                <th className={"text-center"}>Completed</th>
                                <th className={"text-center"}>% of Goal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pd.userProductivity && pd.userProductivity.map((u,i) => <tr key={i}>
                                        <td>{u.displayName}</td>
                                        <td className={"task-stats-cell"}>{Math.ceil(pd.dailyDeptGoal/this.state.teamSize)}</td>
                                        <td className={"task-stats-cell"}>{u.completedTasks}</td>
                                        <td className={"task-stats-cell"}>{((u.completedTasks/Math.ceil(pd.dailyDeptGoal/this.state.teamSize))*100).toFixed(1)+'%'}</td>
                                    </tr>)
                            }
                            <tr className={"font-weight-bold"}>
                                <td>Team</td>
                                <td className={"task-stats-cell"}>{pd.dailyDeptGoal}</td>
                                <td className={"task-stats-cell"}>{pd.userProductivity.reduce((a, c) => a + c.completedTasks, 0)}</td>
                                <td className={"task-stats-cell"}>{pd.userProductivity.length > 0 ? Math.round((pd.userProductivity.reduce((a, c) => a + c.completedTasks, 0)/pd.dailyDeptGoal)*100).toFixed(1)+'%' : 'N/A'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </>}
                {ut && <><h5>My Work Completed Today</h5>
                    <Table striped borderless size={"sm"} className={"mb-5"}>
                        <thead>
                        <tr>
                            <th className={"text-center"}>Completed At</th>
                            <th className={"text-center"}>Acct</th>
                            <th className={"text-center"}>Pt Last</th>
                            <th className={"text-center"}>Task</th>
                            <th className={"text-center"}>Reference Date</th>
                            <th className={"text-center"}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            ut.map((t,i) => <tr key={i}>
                                <td className={"text-center"}>{moment(t.closedAt).format('M/D/YY h:mm a')}</td>
                                <td className={"text-center"}>{t.externalPatientId}</td>
                                <td className={"text-center"}>{t.ptLast}</td>
                                <td className={"text-center"}>{t.taskType}</td>
                                <td className={"text-center"}>{moment(t.referenceDate).format('M/D/YY')}</td>
                                <td className={"text-center"}>{t.status}</td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </>}

            </ModalBody>
        </Modal>
    }
}