import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Col, InputGroup, Modal, ModalBody, Row, Table, Form, Button} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment-timezone";
import {PatientSearch} from "../common/patient/PatientSearch";
import SelectUserModal from "../common/SelectUserModal";

export default class WorkTaskSearchModal extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            patientId: null,
            lastNameFrom: null,
            lastNameTo: null,
            newOnly: false,
            action: "",
            showUsersModal: false,
            searchMode: "PATIENT",
            results: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            this.setState({patientId: null, results: null})
        }
    }

    doSearch = () => {
        let s = this.state;
        let lnf = '';
        let lnt = '';
        let pid = '';

        if ("RANGE" == this.state.searchMode) {
            lnf = s.lastNameFrom ? `&lastNameFrom=${this.state.lastNameFrom}` : ``;
            lnt = s.lastNameTo ? `&lastNameTo=${this.state.lastNameTo}` : ``;
        } else {
            pid = s.patientId ? `&patientId=${this.state.patientId}` : ``;
        }

        let statuses = this.state.newOnly ? '&statuses=NEW' : '';

        this.context.apiRequest('GET', `/workTasks/search?category=${this.props.category}${pid}${lnf}${lnt}${statuses}`)
            .then(d => {
                if (d && d.data) {
                    this.setState({results: d.data});
                }
            })
    }

    patientSelected = (patientId) => {
        if (patientId) {
            this.setState({patientId, searchMode: "PATIENT"});
        }
    }

    toggleAll = (e) => {
        let newResults = [...this.state.results];
        newResults.forEach(r => r.selected = e.target.checked);
        this.setState({results: newResults});
    }

    toggleOne = (i) => {
        let newResults = [...this.state.results];
        newResults[i].selected = !(newResults[i].selected || false);
        this.setState({results: newResults});
    }

    actionSelected = (e) => {
        let val = e.target.value;
        if (val === "ASSIGN") {
            this.setState({showUsersModal: true});
        }
        this.setState({action: ""});
    }

    userSelected = (user) => {
        let taskIds = this.state.results.filter(r => r.selected).map(r => r.id);
        if (taskIds.length > 0) {
            this.context.apiRequest('POST', `/workTasks/bulkAssign`, {userId: user.userId, taskIds})
                .then(() => this.doSearch(this.state.lastSearchWasRange));
        }
        this.setState({showUsersModal: false});
    }



    render() {
        let canSearch = false;
        if (this.state.searchMode === "PATIENT" && this.state.patientId) canSearch = true;
        else if (this.state.searchMode === "RANGE" && this.state.lastNameFrom && this.state.lastNameTo) canSearch = true;

        return <Modal show={this.props.show} onHide={this.props.onHide} size={"xl"} scrollable={true}>
            <ModalHeader closeButton>Search Tasks</ModalHeader>
            <ModalBody>
                <Form.Row>
                    <Col md={4}>
                        <Form.Check label={"Single Patient"} className={"fullSizeLabel"} inline type={"radio"} name={"searchMode"} value={"PATIENT"} checked={this.state.searchMode === "PATIENT"} onChange={e => this.setState({searchMode: e.target.value})}/>
                    </Col>
                    <Col md={3}>
                        <Form.Check label={"Name Range"} className={"fullSizeLabel"} inline type={"radio"} name={"searchMode"} value={"RANGE"} checked={this.state.searchMode === "RANGE"} onChange={e => this.setState({searchMode: e.target.value})}/>
                    </Col>
                    <Col md={3}>
                        <h6>Filters</h6>
                    </Col>
                    <Col md={2}>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={4} className={"pr-4"}>
                        <PatientSearch id={"authTasksPatientSearch"} clearOnSelect={false} showNewButton={false} showGoButton={false} patientSelected={this.patientSelected}/>
                    </Col>
                    <Col md={3} className={"pr-4"}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>From/To</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control style={{textAlign: 'center'}}
                                          value={this.state.lastNameFrom || ''}
                                          onChange={e => this.setState({lastNameFrom: e.target.value})}
                                          minLength={1} maxLength={3}
                                          placeholder={"AA"}/>
                            <Form.Control style={{textAlign: 'center'}}
                                          value={this.state.lastNameTo || ''}
                                          onChange={e => this.setState({lastNameTo: e.target.value})}
                                          minLength={1} maxLength={3}
                                          placeholder={"BB"}/>
                        </InputGroup>
                    </Col>
                    <Col md={3} className={"pr-4"}>
                        <Form.Check id={"newOnlyCheckbox"} inline label={"New/Unassigned Only"} type={"switch"} size={"sm"} checked={this.state.newOnly} value={"true"} onChange={() => this.setState({newOnly: !this.state.newOnly})}/>
                    </Col>
                    <Col md={2} className={"text-right"}>
                        <Button onClick={this.doSearch} variant={"secondary"} disabled={!canSearch}>Search</Button>
                    </Col>
                </Form.Row>
                <SelectUserModal department={this.props.department} show={this.state.showUsersModal} onOk={this.userSelected} onCancel={() => this.setState({showUsersModal: false})} role={"Authorizations"}/>
                <Row style={{minHeight: "400px"}}>
                    {
                        <Col>
                                {
                                    this.state.results == null ? <h6>Enter criteria and click Search above.</h6> : (
                                    this.state.results.length > 0 ? <Table striped size={"sm"}>
                                        <thead>
                                        <tr>
                                            <th colSpan={8}></th>
                                            <th colSpan={2} className={"text-right"}>
                                                <Form.Control as="select" size={"sm"} disabled={!this.state.results.some(r=>r.selected)}
                                                              value={this.state.action} onChange={this.actionSelected}>
                                                    <option value={""} disabled>Action</option>
                                                    <option value={"ASSIGN"}>Assign To...</option>
                                                </Form.Control>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Created</th>
                                            <th>Pt Last</th>
                                            <th>Task Type</th>
                                            <th>Reference/Appt Date</th>
                                            <th>Reasons</th>
                                            <th>Due Date</th>
                                            <th>Status</th>
                                            <th>Reason</th>
                                            <th>Assignee</th>
                                            <th>Closed</th>
                                            <th className={"text-center"}>
                                                <Form.Check checked={!this.state.results.some(r=>!r.selected)} value={"true"} onChange={this.toggleAll}/>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.results.map((r,i) => <tr key={r.id}>
                                                <td>{moment(r.created).format('M/D/YY h:mm A')}</td>
                                                <td>{r.patientLast}</td>
                                                <td>{r.taskType}</td>
                                                <td>{moment(r.referenceDate).format('M/D/YY')}</td>
                                                <td>{r.reasonCodes ? r.reasonCodes.join(', ') : ``}</td>
                                                <td>{moment(r.dueDate).format('M/D/YY')}</td>
                                                <td>{r.status}</td>
                                                <td>{r.pendingReason || r.closedReason || ''}</td>
                                                <td>{r.userName}</td>
                                                <td>{r.closedAt ? moment(r.closedAt).format('M/D/YY h:mm A') : ''}</td>
                                                <td className={"text-center"}><Form.Check id={`selected-${r.id}`} value={"true"} checked={r.selected || false} onChange={() => this.toggleOne(i)}/></td>
                                            </tr>)
                                        }
                                        </tbody>
                                        </Table>
                                    : <h6>No tasks found.</h6>)
                                }
                        </Col>
                    }
                </Row>
            </ModalBody>
        </Modal>
    }
}