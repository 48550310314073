import React from 'react';
import './App.css';
import {GlobalContext} from './GlobalContext';
import Cookies from 'universal-cookie';
import {AppUI} from './components/AppUI';
import {LoginForm} from './components/LoginForm';
import {checkCache, putCache} from "./utils/Cache";
import {ConfirmModal} from "./components/common/ConfirmModal";
import moment from "moment-timezone";

const AUTO_LOGOUT_MINUTES = 30;
const NEW_TOKEN_MINUTES = 5;

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            token: null,
            tokenTimestamp: 0,
            userProfile: null,
            userDidAuthenticate: this.userDidAuthenticate,
            userDidLogout: this.userDidLogout,
            apiRequest: this.apiRequest,
            resetLogoutTimer: this.resetLogoutTimer,
            refreshMyProfile: this.refreshMyProfile,
            cachedGet: this.cachedGet,
            toast: this.toast,
            hideToast: this.hideToast,
            showToast: false,
            toastMessage: '',
            toastTitle: '',
            hasAnyRole: this.hasAnyRole,
            confirmConfig: {
                visible: false
            },
            confirm: this.confirm
        };
    };

    confirm = (cb, prompt, title) => {
        let cfg = {
            prompt: prompt || 'Are you sure?',
            title: title || 'Confirm',
            callback: () => {
                cb();
                this.closeConfirm();
            },
            visible: false
        };
        this.setState({confirmConfig: cfg}, () => this.setState({confirmConfig: {...cfg, visible: true}}));
    };

    closeConfirm = () => {
        let cc = this.state.confirmConfig;
        this.setState({confirmConfig: {...cc, visible: false}});
    }

    hasAnyRole = (roles) => {
        if (roles && this.state.userProfile && this.state.userProfile.roles) {
            if (Array.isArray(roles)) {
                return (this.state.userProfile.roles.find(r => roles.includes(r)) != null);
            } else {
                return this.state.userProfile.roles.includes(roles);
            }
        }
        return false;
    };

    componentDidMount() {
        //check for token cookie on page load (in case of page refresh)
        this.refreshToken();
    }

    refreshToken = () => {
        let token = this.state.token;
        const cookies = new Cookies();
        if (token === null) {
            token = cookies.get("t");
        }

        if (token) {
            this.setState({token: token}, () => {
                this.userDidAuthenticate(token);
            });
        } else {
            this.userDidLogout();
        }
    };

    cachedGet = (uri, expirySeconds) => {
        let cached = checkCache(uri);
        if (cached) {
            return Promise.resolve(cached);
        } else {
            return this.apiRequest("GET", uri)
                .then(d => {
                    if (d) {
                        putCache(uri, d, expirySeconds || 60);
                    }
                    return Promise.resolve(d);
                });
        }
    }

    /**
     * Returns a Promise that will resolve to the response data.
     * @param command
     * @returns {Promise<Data>}
     */
    apiRequest = (method, uri, payload, dontResetLogoutTimer) => {
        let settings = {
            method: method,
            headers: {
                "Accept": "application/json"
            }
        };
        if (this.state.token) {
            settings.headers['Authorization'] = "Bearer " + this.state.token;
            if (uri !== '/login' && !dontResetLogoutTimer) this.maybeGetNewToken();
        }
        if (payload) {
            settings.headers["Content-Type"] = "application/json";
            settings.body = JSON.stringify(payload);
        }
        const res = fetch("/web-api" + uri, settings)
            .then(this.checkStatus)
            .then(this.json)
            .catch((err) => {
                console.log(err.message);
                if ("Access Denied" !== err.message) {
                    //internal
                    this.toast('Error', 'Oops! Something went wrong on our end.');
                    return Promise.resolve({});
                }
            })
            .finally(() => {
                if (!dontResetLogoutTimer) {
                    this.resetLogoutTimer();
                }
            });

        return res;
    };

    maybeGetNewToken = async () => {
        let tokenAge = (Date.now() - this.state.tokenTimestamp) / 60000;
        if (this.state.authenticated && this.state.token && tokenAge >= NEW_TOKEN_MINUTES) {
            fetch("/web-api/login/refreshToken", {
                "method": "POST",
                "headers": {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + this.state.token
                }
            })
            .then(this.checkStatus)
            .then(this.json)
            .then(resp => {
                this.userDidAuthenticate(resp.data.token, this.state.userProfile);
            })
            .catch((err) => {
                console.log(err.message);
                if ("Access Denied" !== err.message) {
                    //internal
                    console.log('showing alert dialog');
                    this.toast('Error', 'Oops! Something went wrong on our end.');
                    return Promise.resolve({});
                }
            });
        }

    }

    toast = (title, message) => {
        this.setState({toastTitle: title, toastMessage: message, showToast: true});
    }

    hideToast = () => {
        this.setState({showToast: false});
    }

    json = (response) => {
        return response.json();
    };

    logoutAfter = 0;
    logoutCheckInterval = null;

    resetLogoutTimer = () => {
        if (this.state.authenticated) {
            //trigger a logout in X min if nothing else is done
            this.logoutAfter = Date.now() + (AUTO_LOGOUT_MINUTES*60000);
            //set up the check interval if it doesn't exist already
            if (this.logoutCheckInterval) window.clearInterval(this.logoutCheckInterval);
            this.logoutCheckInterval = window.setInterval(this.maybeLogout, 1000);
        }
    };

    maybeLogout = () => {
        if (!this.state.authenticated || Date.now() >= this.logoutAfter) {
            this.userDidLogout();
        }
    }

    checkStatus = (response) => {
        //accept anything between 200-300, and 400 for 'bad input' responses
        if ((response.status >= 200 && response.status < 300) || response.status === 400) {
            return Promise.resolve(response);
        } else if (response.status === 403) {
            //log them out if access denied
            this.userDidLogout();
            return Promise.reject(new Error("Access Denied"));
        }
        return Promise.reject(new Error(response.statusText));
    };

    userDidAuthenticate = (t, userProfile) => {
        const cookies = new Cookies();
        let expiry = moment.tz('UTC').add(AUTO_LOGOUT_MINUTES, 'minute').toDate();
        cookies.set("t", t, {path: "/", expires: expiry});
        this.setState({
            authenticated: true,
            token: t,
            tokenTimestamp: Date.now()
        }, () => {

            if (userProfile) {
                this.setState({userProfile}, this.resetLogoutTimer);
            } else {
                this.refreshMyProfile();
            }
        });
    };

    refreshMyProfile = () => {
        return this.apiRequest("GET", "/my/profile")
            .then(d => {
                if (d && d.data) {
                    this.setState({
                        userProfile: d.data
                    });
                }
            })
            .then(this.resetLogoutTimer);
    };

    userDidLogout = () => {
        const cookies = new Cookies();
        cookies.remove("t");
        if (this.logoutCheckInterval) window.clearInterval(this.logoutCheckInterval);
        this.setState({
            authenticated: false,
            token: null,
            roles: null,
            searchTerm: ""
        });
    };

    render() {
        let cc = this.state.confirmConfig;
        return (
            <GlobalContext.Provider value={this.state}>
                <div className="App">
                    <ConfirmModal show={cc.visible} prompt={cc.prompt} title={cc.title} onOk={cc.callback} onCancel={this.closeConfirm}/>
                    {this.state.authenticated ?
                        <AppUI toastMessage={this.state.toastMessage} toastTitle={this.state.toastTitle}
                               showToast={this.state.showToast}/>
                        : <LoginForm authenticated={this.state.authenticated}
                                     showSignup={() => this.setState({signingUp: true})}/>}
                </div>
            </GlobalContext.Provider>
        );
    };
}

export default App;
