import React from "react";
import {Button, Col, Modal, Row, Table} from "react-bootstrap";
import {GlobalContext} from "../../GlobalContext";
import {Glyph} from "../common/Glyph";
import {SpinnerWrapper} from "./SpinnerWrapper";

export class ItemList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            modalVisible: false,
            currentItem: this.newItem(),
            loading: true,
            pageNum: 0,
            hasMore: false
        }
    }

    componentDidMount() {
        this.loadItems();
        this.setState({currentItem: this.newItem()});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps[this.updateProperty] !== this.props[this.updateProperty]) {
            this.loadItems();
            this.setState({currentItem: this.newItem()});
        }
    }

    baseUrl = '/';
    idField = 'id';
    updateProperty = 'parentId';
    itemTypeName = 'Item';
    headerColumns = ['ID'];
    headerColumnClasses = null;
    dataColumnClasses = null;
    addSupported = false;
    editSupported = false;
    deleteSupported = false;
    paging = false;
    modalClass = Modal;

    /**
     * Can be overridden to provide a custom baseUrl based on props for example.
     * @returns {string|*}
     */
    getBaseUrl = () => {
        return this.baseUrl;
    };

    /**
     * Override me
     * @param item
     * @returns array the contents of the table cells for a particular item.
     */
    getItemCellContents = (item) => {
        return [item.id];
    };

    reloadList = () => {
        if (this.paging) {
            this.setState({pageNum: 0, items: [], hasMore: false, loading: true}, this.loadItems);
        } else {
            this.loadItems();
        }
    };

    loadItems = () => {
        this.context.apiRequest("GET", this.getBaseUrl() + (this.paging ? '?page=' + this.state.pageNum : ''))
            .then(d => {
                if (d.data) {
                    if (this.paging) {
                        let newData = this.state.items.concat(d.data);
                        // If using paging, we will assume existing data and incoming data are both arrays.
                        let hasMore = true;
                        if (d.metadata.pageInfo.pageNumber >= d.metadata.pageInfo.totalPages-1) {
                            // This was the last page
                            hasMore = false;
                        }
                        this.setState({items: newData, pageNum: this.state.pageNum+1, hasMore, loading: false});
                    } else {
                        this.setState({items: d.data, loading: false});
                    }
                }
            });
    };

    editItem = (id) => {
        let item = id > 0 ? this.state.items.find(i => i[this.idField] == id) : this.newItem();
        this.setState({currentItem: item}, () => this.setState({modalVisible: true}));
    };

    getDeleteConfirmTitle = (id) => {
        return 'Confirm delete';
    };

    getDeleteConfirmPrompt = (id) => {
        return `Delete this ${this.itemTypeName}?`;
    };

    deleteItem = (id) => {
        this.context.confirm(() =>
            this.context.apiRequest("DELETE", `${this.getBaseUrl()}/${id}`)
                .then(this.loadItems), this.getDeleteConfirmPrompt(id), this.getDeleteConfirmTitle(id));
    };

    modalOk = (item) => {
        this.context.apiRequest("POST", this.getBaseUrl(), item)
            .then(() => {
                this.reloadList();
                this.setState({modalVisible: false, currentItem: this.newItem()});
            });
    };

    modalCancel = () => {
        this.setState({modalVisible: false, currentItem: this.newItem()});
    };

    newItem = () => {
        return {
            id: 0
        };
    };

    renderAfter = () => {
        return <></>;
    };

    renderBefore = () => {
        return <></>;
    };

    render() {
        let idField = this.idField;
        let headerClasses = this.headerColumnClasses;
        let dataClasses = this.dataColumnClasses;

        return <SpinnerWrapper visible={this.state.loading}>
            {
                this.renderBefore()
            }
            <Row>
                <Col>
                    <Table striped hover={this.editSupported}>
                        <thead>
                            <tr>
                                {
                                    this.headerColumns.map((c, index) => {
                                        let cls = headerClasses && headerClasses.length > index ? headerClasses[index] : '';
                                        return <th key={index} className={cls}>{c}</th>;
                                    })
                                }
                                {
                                    this.addSupported ? <th className={"text-right"}>
                                        <Glyph name={"plus-circle"} label={`Add ${this.itemTypeName}`} onClick={() => this.editItem(0)} className={"clickable"}/>
                                        {React.createElement(this.modalClass, {show: this.state.modalVisible, item: this.state.currentItem, onOk: this.modalOk, onCancel: this.modalCancel, size: this.props.modalSize, ...this.props.extraData})}
                                    </th> : <th/>
                                }
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.items.map(i => <tr key={i[idField]}>
                            {
                                this.getItemCellContents(i).map((c, index) => {
                                    let cls = dataClasses && dataClasses.length > index ? dataClasses[index] : '';
                                    return <td key={index} className={cls + ' ' + (this.editSupported ? "clickable" : "")} onClick={this.editSupported ? (() => this.editItem(i[idField])) : () => {}}>{c}</td>;
                                })

                            }
                            {
                                this.deleteSupported ? <td className={"text-right align-middle"}>
                                    <Glyph name={"x"} label={"Remove"} className={"clickable"} onClick={() => this.deleteItem(i[idField])}/>
                                </td> : <td/>
                            }
                        </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {
                this.state.hasMore ? <Row><Col xs={12} className={"text-center mb-3"}><Button size={"sm"} variant={"outline-secondary"} onClick={this.loadItems}>Load More</Button></Col></Row> : <span/>
            }
            {
                this.renderAfter()
            }
        </SpinnerWrapper>
    }
}